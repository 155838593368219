import { Button } from "components/common";
import { MultipleCheckedItemsToolbar } from "components/common/multipleCheckedItemsToolbar/MultipleCheckedItemsToolbar";
import { useQuery, useSelector, useStateModal, useToggle, useToastr } from "hooks";
import { HighlightedRow } from "api/other/models";
import { useState } from "react";
import { handleDownloadMultipleOrderXml } from "./handleDownloadMultipleOrderXml";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import {
  BulkSalesInvoiceConfirmPreview,
  InvoiceType,
  TradingDocumentType,
} from "api/trading-documents/models";
import { handleBulkEditInvoices } from "./handleBulkEditInvoices";
import { Modal, Select } from "components/utils";
import { AccountancyAccount } from "api/accountancy-accounts/models";
import { useMutation } from "hooks/useMutation";
import { patchBulkInvoices } from "api/invoices/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useQueryClient } from "react-query";
import { handleXmlDownloadForMultipleCorrections } from "./utils/handleXmlDownloadForMultipleCorrections";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiRule } from "components/miloDesignSystem/atoms/icons/MdiRule";
import { MdiAssign } from "components/miloDesignSystem/atoms/icons/MdiAssign";
import { MdiBin } from "components/miloDesignSystem/atoms/icons/MdiBin";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import {
  postFiscalizeMultipleReceipts,
  tradingDocumentFileFactory,
  postBulkConfirmSalesInvoice,
} from "api/trading-documents/calls";
import { getAnyErrorKey } from "utilities";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { UUID } from "api/types";
import { fileDownloader } from "fileDownloader";
import { ReplyModal } from "./ReplyModal";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  actualPage: string | number;
  close: () => void;
  quantity: number;
  highlightedRows?: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
  tradingDocument?: { type: TradingDocumentType; invoiceType: InvoiceType };
}
export const ActionToolbar = ({
  actualPage,
  close,
  quantity,
  highlightedRows,
  setHighlightedRows,
  tradingDocument,
}: Props) => {
  const { query } = useQuery();
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const modal = useToggle();
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);
  const [chosenAccountancyAccount, setChosenAccountancyAccount] = useState<AccountancyAccount>();
  const panelId = query.panelId;
  const queryClient = useQueryClient();
  const toastr = useToastr();

  const handleBulkSalesInvoicesPdfDownload = async (salesInvoicesIds: UUID[]) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.bulkSalesInvoicesPdf(salesInvoicesIds);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.lazyClose();
      toastr.open({
        type: "failure",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  const fiscalizeMultipleReceiptsMutation = useMutation(
    postFiscalizeMultipleReceipts,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się",
          text: "Wysłano paragony",
        });
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const bulkConfirmSalesInvoicesMutation = useMutation(
    postBulkConfirmSalesInvoice,
    ({ queryClient }) => ({
      onSuccess: payload => {
        if (panelId) {
          queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
        }
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());

        replyModal.open(payload.message);
      },
    }),
  );

  const patchMutation = useMutation(patchBulkInvoices, {
    onSuccess: args => {
      queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
    },
    onSettled: args => {
      modal.close();
    },
  });

  assertIsDefined(highlightedRows);

  return (
    <MultipleCheckedItemsToolbar
      actionButtons={
        <>
          {tradingDocument?.invoiceType === "SALES" && (
            <Tooltip title="Zatwierdź faktury">
              <IconButton
                icon={MdiCheck}
                onClick={() => {
                  bulkConfirmSalesInvoicesMutation.mutate({
                    tradingDocumentsIds: highlightedRows.map(row => String(row.id)),
                  });
                }}
                variant="whiteT"
              />
            </Tooltip>
          )}
          <Tooltip title="Zażądaj zmian">
            <IconButton icon={MdiRule} variant="whiteT" />
          </Tooltip>
          <Tooltip title="Przypisz">
            <IconButton icon={MdiAssign} variant="whiteT" />
          </Tooltip>
          <Tooltip title="Usuń">
            <IconButton icon={MdiBin} variant="whiteT" />
          </Tooltip>
          <Tooltip title="Zmień konta księgowe dla zaznaczonych">
            <IconButton icon={MdiEdit} onClick={() => modal.open()} variant="whiteT" />
          </Tooltip>
          {tradingDocument &&
            (tradingDocument.invoiceType === "SALES" ||
              tradingDocument.invoiceType === "CORRECTION") && (
              <Tooltip title="Pobierz PDF dla zaznaczonych">
                <IconButton
                  icon={MdiDownloadPdf}
                  onClick={() =>
                    handleBulkSalesInvoicesPdfDownload(
                      highlightedRows ? highlightedRows.map(row => String(row.id)) : [],
                    )
                  }
                  variant="whiteT"
                />
              </Tooltip>
            )}

          {tradingDocument && tradingDocument.invoiceType !== "CORRECTION" && (
            <Tooltip title="Pobierz XML dla zaznaczonych">
              <IconButton
                icon={MdiDownloadXml}
                onClick={() => {
                  handleDownloadMultipleOrderXml({
                    tradingDocument: tradingDocument,
                    tradingDocumentsString: parseRows(highlightedRows).string,
                    downloadFeedbackToastr,
                  });
                }}
                variant="whiteT"
              />
            </Tooltip>
          )}

          {tradingDocument && tradingDocument.invoiceType === "SALES" && (
            <Tooltip title="Wyślij paragony do drukarki dla zaznaczonych">
              <IconButton
                icon={MdiReceiptLong}
                onClick={() => {
                  fiscalizeMultipleReceiptsMutation.mutate({
                    tradingDocuments: highlightedRows.map(row => String(row.id)),
                  });
                }}
                variant="whiteT"
              />
            </Tooltip>
          )}

          {tradingDocument && tradingDocument.invoiceType === "CORRECTION" && (
            <Tooltip title="Pobierz XML dla zaznaczonych">
              <IconButton
                icon={MdiDownloadXml}
                onClick={() => {
                  handleXmlDownloadForMultipleCorrections(
                    tradingDocument,
                    parseRows(highlightedRows).string,
                    downloadFeedbackToastr,
                  );
                }}
                variant="whiteT"
              />
            </Tooltip>
          )}

          {replyModal.isOpen && replyModal.state && (
            <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
          )}

          <Modal isOpen={modal.isOpen} close={modal.close}>
            <div className="d-flex flex-column align-items-center justify-content-center pb-5">
              <h1 className="body-14 text-center mx-0">
                Jakie konto księgowe przypisać dla zaznaczonych faktur?
              </h1>
              <Select
                width="full"
                size="small"
                items={ledgerAccounts}
                onChange={value => {
                  if (value) setChosenAccountancyAccount(value);
                }}
              />
              <Button
                onClick={() => {
                  if (chosenAccountancyAccount) {
                    handleBulkEditInvoices({
                      //@ts-ignore
                      tradingDocumentsArray: parseRows(highlightedRows).array,
                      fieldToUpdate: "ledgerAccount",
                      valueToUpdate: chosenAccountancyAccount.id,
                      mutation: patchMutation,
                    });
                  }
                }}
                className="mt-4 px-3"
                kind="secondary-grey"
                size="small"
              >
                Zatwierdź
              </Button>
            </div>
          </Modal>
        </>
      }
      close={close}
      quantity={quantity}
    />
  );
};

const parseRows = (rows: HighlightedRow[]) => {
  let stringArray = rows.map(tradingDocument => tradingDocument.id);
  return {
    string: stringArray.join(","),
    array: stringArray,
  };
};
