import { Header as HeaderType, Table, flexRender } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import { TABLE_FILL_WIDTH_ID, TABLE_PADDING_HORIZONTAL } from "../../constants";
import styles from "./Header.module.css";
import { TableProps } from "../../types";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { PropsWithChildren } from "react";
import { Loader } from "components/miloDesignSystem/atoms/loader";
import {
  DEFAULT_CELL_PADDING,
  DEFAULT_HEADER_LOADER_WIDTH,
  DEFAULT_LOADER_HEIGHT,
} from "../tableLoader/TableLoader";

export const Header = <T,>({
  isLoading,
  showIndexColumn,
  table,
  uiSchema,
}: {
  isLoading: boolean;
  showIndexColumn: boolean | undefined;
  table: Table<T>;
  uiSchema: TableProps<T>["uiSchema"];
}) => {
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;

  const getLoaderCellWidth = (header: HeaderType<T, unknown>): number => {
    if (Boolean(header.subHeaders.length)) return 0;

    const columnDefHeader = header.column.columnDef.header;
    const length = header.column.columnDef.header?.length;
    if (columnDefHeader !== undefined && typeof columnDefHeader !== "string")
      return header.getSize();
    if (length === undefined) return DEFAULT_HEADER_LOADER_WIDTH;
    return (Number(DEFAULT_LOADER_HEIGHT) - DEFAULT_CELL_PADDING) * length;
  };

  return (
    <>
      <div
        style={{
          width,
          maxWidth: width,
          minWidth: width,
          backgroundColor: colorPalette[uiSchema.header.backgroundColor],
        }}
        className={cx("d-flex align-items-center flex-column py-1", styles.header)}
      >
        {table.getHeaderGroups().map(headerGroup => (
          <div className="d-flex align-items-center flex-1" key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isParent = header.subHeaders.length > 0 && !header.isPlaceholder;

              return (
                <div
                  className={cx("d-flex align-items-center", {
                    "justify-content-end": isParent,
                    "pr-2": !isLoading && header.id !== TABLE_FILL_WIDTH_ID,
                  })}
                  style={{
                    width:
                      showIndexColumn && isLoading && header.index === 0 ? 0 : header.getSize(),
                    minWidth:
                      showIndexColumn && isLoading && header.index === 0 ? 0 : header.getSize(),
                    maxWidth:
                      showIndexColumn && isLoading && header.index === 0 ? 0 : header.getSize(),
                  }}
                >
                  {isParent &&
                    !isLoading &&
                    flexRender(header.column.columnDef.header, header.getContext())}
                  {!isParent && isLoading && (
                    <HeaderCell>
                      {showIndexColumn && header.index === 0 ? null : (
                        <div
                          className="d-flex align-items-center pr-2"
                          style={{
                            width: header.getSize(),
                            minWidth: header.getSize(),
                            maxWidth: header.getSize(),
                          }}
                        >
                          <Loader
                            height={Number(DEFAULT_LOADER_HEIGHT)}
                            width={getLoaderCellWidth(header)}
                          />
                        </div>
                      )}
                    </HeaderCell>
                  )}
                  {!isParent && !isLoading && (
                    <HeaderCell>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </HeaderCell>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export const HeaderCell = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <Typography
      className={cx("w-100", className)}
      fontSize="12"
      fontWeight="400"
      color="neutralBlack48"
    >
      {children}
    </Typography>
  );
};
