import { ExternalManufacturerItemsTab, OrderStatus } from "api/external-manufacturing/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: ExternalManufacturerItemsTab;
};

const statusDict: Record<ExternalManufacturerItemsTab, OrderStatus | "true"> = {
  [ExternalManufacturerItemsTab["not-sent"]]: OrderStatus.NOT_SENT,
  [ExternalManufacturerItemsTab["sent"]]: OrderStatus.SENT,
  [ExternalManufacturerItemsTab["received"]]: OrderStatus.RECEIVED,
  [ExternalManufacturerItemsTab["cancelled"]]: "true",
  [ExternalManufacturerItemsTab["all"]]: "" as OrderStatus,
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  if (tab === ExternalManufacturerItemsTab["cancelled"]) {
    return queryString.stringify({
      ...query,
      isCancelled: statusDict["cancelled"],
    });
  }

  const tabsQuery = {
    status: statusDict[tab],
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
