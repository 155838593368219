import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import fv_production from "assets/images/favicons/fv_production.svg";
import manufacturingIcon from "assets/images/Processing.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_warehouse.svg";
import { manufacturerItems } from "./routes/manufacturerItems";
import { dashboard } from "./routes/dashboard";
import { manufacturerLineItems } from "./routes/manufacturerLineItems";

export const externalManufacturerPanel: ModuleNavigation = {
  favicon,
  labelBackground: colorPalette.deepOrange200,
  label: "Panel pracownika",
  iconBackground: colorPalette.deepOrange600,
  icon: fv_production,
  menuIcon: manufacturingIcon,
  name: "EXTERNAL_MANUFACTURER_PANEL",
  url: "/external-manufacturer-panel",
  navigationSections: [
    {
      navigationList: [dashboard, manufacturerItems, manufacturerLineItems],
    },
  ],
};
