import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RemainingOrders } from "./remainingOrders/RemainingOrders";
import { LabelList } from "./labelList/LabelList";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("externalManufacturerItems");
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data, error, isLoading } = externalManufacturingActions.useManufacturerLineItem(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(data);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader close={close} signature={data.signature} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody hasCommentsModule>
          <RightPanelSection padding="px-3 pt-2 pb-0">
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="26" fontWeight="700">
                {data.signature}
              </Typography>
            </div>
          </RightPanelSection>
          <GeneralInfoSection lineItemDetails={data} />
          <RemainingOrders lineItemDetails={data} />
          <LabelList />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          paramName="externalManufacturingLineItem"
          endpointUrl={`/external-manufacturing/orders/line-items-comments`}
          commentedObject={data}
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
