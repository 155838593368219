import { TableProps } from "components/miloDesignSystem/molecules/table/types";
import { AvailableViewKeys } from "ducks/ui";
import { useDrawer } from "hooks/useDrawer";
import styles from "./DrawerHelpers.module.css";
import { UUID } from "api/types";

type DrawerHelpersType = {
  isActive: (id: number | string) => boolean;
  isLastOpened: (id: number | string) => boolean;
  togglePanel: (id: number | string, additionalId?: number | string) => void;
};

interface Props {
  children: (drawerHelpers: DrawerHelpersType) => JSX.Element;
  name: AvailableViewKeys;
  overrittenPanelKey?: string;
}

export const DrawerHelpers = ({ children, name }: Props) => {
  const { isActive, isLastOpened, togglePanel } = useDrawer(name);
  return children({ isActive, isLastOpened, togglePanel });
};

export const getTablePropsBasedOnDrawerHelpers = <T extends { id: UUID | number }>(
  drawerHelpers: DrawerHelpersType,
): Pick<TableProps<T>, "onRowClick" | "overrides"> => {
  return {
    onRowClick: drawerHelpers.togglePanel,
    overrides: row => {
      if (drawerHelpers.isActive(row.id ?? "")) {
        return {
          row: {
            className: styles.active,
          },
        };
      }
      if (drawerHelpers.isLastOpened(row.id ?? "")) {
        return {
          row: {
            className: styles.lastOpened,
          },
        };
      }
      return {};
    },
  };
};
