import { LoadingDetails } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";

interface Props {
  loading: LoadingDetails;
}

export const LoadingDeparture = ({ loading }: Props) => {
  if (!loading.estimatedTimeOfDeparture)
    return (
      <Typography fontSize="14" fontWeight="500">
        ---
      </Typography>
    );
  return (
    <Typography fontSize="14" fontWeight="500">
      {loading.estimatedTimeOfDeparture
        ? dateFns.formatRelative(new Date(loading.estimatedTimeOfDeparture))
        : "---"}
    </Typography>
  );
};
