import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  date: string | null;
}

export const InStockTime = ({ date }: Props) => {
  if (!date) return <EmptyValue />;
  return (
    <Typography fontSize="12" fontWeight="600">
      {date ? dateFns.formatRelative(new Date(date)) : "---"}
    </Typography>
  );
};
