import fv_production from "assets/images/favicons/fv_production.svg";
import manufacturingIcon from "assets/images/Processing.svg";
import homeIcon from "assets/images/homeLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import favicon from "assets/images/favicons/fv_production.svg";
import { MdiListAlt } from "components/miloDesignSystem/atoms/icons/MdiListAlt";
import { MdiSingleOrders } from "components/miloDesignSystem/atoms/icons/MdiSingleOrders";
import { MdiOrdersGroup } from "components/miloDesignSystem/atoms/icons/MdiOrdersGroup";
import { MdiAnalytics } from "components/miloDesignSystem/atoms/icons/MdiAnalytics";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { MdiGroupByProducer } from "components/miloDesignSystem/atoms/icons/MdiGroupByProducer";
import { ExternalManufacturingOrdersRouting } from "routes/externalManufacturing/ExternalManufacturingOrdersRouting";
import { FilterList } from "../../components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";
import { executionStatusDict } from "pages/externalManufacturerPanel/manufacturerLineItemsList/components/ExecutionStatus";
import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";

export const externalManufacturingConfig: ModuleNavigation = {
  favicon,
  labelBackground: colorPalette.deepOrange200,
  label: "Zlecenia do producentów",
  iconBackground: colorPalette.deepOrange600,
  icon: fv_production,
  menuIcon: manufacturingIcon,
  name: "EXTERNAL_MANUFACTURING",
  url: "/external-manufacturing",
  navigationSections: [
    {
      navigationList: [
        {
          url: "dashboard",
          label: "Pulpit",
          icon: homeIcon,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "orders",
          label: "Lista zleceń do producentów",
          icon: MdiListAlt,
          subSection: <></>,
          routing: ExternalManufacturingOrdersRouting,
          isAccessible: true,
        },
        {
          url: "manufacturer-line-items",
          label: "Poj. zlecenia do producentów",
          icon: MdiSingleOrders,
          subSection: (
            <FilterList
              filters={[
                {
                  type: FilterType.Select,
                  label: "priorytet",
                  name: "priority",
                  options: Object.entries(priorityDict)
                    .map(([key, value]) => ({
                      label: value.label,
                      value: key,
                    }))
                    .reverse(),
                },
                {
                  type: FilterType.Date,
                  label: "data odbioru",
                  name: "estimatedDeliveryDate",
                },
                {
                  type: FilterType.Select,
                  label: "potwierdzono",
                  name: "hasConfirmedDeliveryDate",
                  options: [
                    { label: "Tak", value: "true" },
                    { label: "Nie", value: "false" },
                  ],
                },
                {
                  type: FilterType.Select,
                  label: "status realizacji",
                  name: "executionStatus",
                  options: Object.entries(executionStatusDict).map(([key, value]) => ({
                    label: value.label.toLocaleLowerCase(),
                    value: key,
                  })),
                },
              ]}
            />
          ),
          routing: ManufacturerLineItems,
        },
        {
          url: "link3",
          label: "Zgrupowane zlecenia producenta",
          icon: MdiOrdersGroup,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "link4",
          label: "Zlecenia zgrupowane po producencie",
          icon: MdiGroupByProducer,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "link5",
          label: "Analityka",
          icon: MdiAnalytics,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
        {
          url: "link6",
          label: "Ustawienia",
          icon: MdiSettings,
          subSection: <></>,
          routing: () => <></>,
          isAccessible: false,
        },
      ],
    },
  ],
};
