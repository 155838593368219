import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Shipment } from "api/shipping/models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { shippingFileFactory } from "api/shipping/calls";
import { dateFns, getAnyErrorKey } from "utilities";
import { useSelector, useToastr } from "hooks";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";

export const useShippingShipmentsColumns = () => {
  //@ts-ignore
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const toastr = useToastr();
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleDownloadDeliveryCostPdf = async (shipmentId: any, shipmentServiceId: any) => {
    const downloadToastr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = shippingFileFactory.shippingLabels(shipmentId, shipmentServiceId);

    const response = await fileDownloader({
      onProgress: downloadToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      downloadToastr.lazyClose();
    } else {
      downloadToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return useCreateTableColumns<Shipment>(({ columnHelper }) => {
    return [
      columnHelper.text(
        row => {
          if (row.created) {
            return dateFns.format(new Date(row.created), "dd.MM.yyyy, HH:mm");
          }
        },
        {
          header: "data nadania",
          size: 120,
        },
      ),
      columnHelper.text(row => row.shippingService.provider, {
        header: "nazwa konta",
        size: 140,
      }),
      columnHelper.text(row => row.order.signature, {
        header: "zamówienie",
        size: 150,
      }),
      columnHelper.accessor(row => row.cashOnDelivery, {
        header: "pobranie",
        size: 130,
        cell: info => {
          if (!Boolean(info.getValue())) return "---";
          return <Tag label="Tak" variant="success" />;
        },
      }),
      columnHelper.text(row => row.trackingNumber, {
        header: "numer nadawczy",
        size: 160,
      }),
      columnHelper.text(
        row => {
          if (row.status === "NEW") {
            return null;
          }
          return row.status;
        },
        {
          header: "status",
          size: 250,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "etykieta",
        size: 50,
        cell: info => {
          return (
            <div>
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadDeliveryCostPdf(info.getValue().id, shippingServices[0].id);
                }}
                variant="blackST"
              />
            </div>
          );
        },
      }),
    ];
  });
};
