import eyeIcon from "assets/images/170.svg";
import cashBlueImg from "assets/images/48.svg";
import {
  deleteRoute,
  postCarrierOrder,
  removeDeliveryConfirmationForRoutes,
} from "api/routes/calls";
import eventBusyIcon from "assets/images/eventBusy.svg";
import editIcon from "assets/images/1.svg";
import closeImg from "assets/images/10.svg";
import historyImg from "assets/images/5c.svg";
import completeImg from "assets/images/7.svg";
import binIcon from "assets/images/81.svg";
import createOrderIcon from "assets/images/80.svg";
import upholsteryIcon from "assets/images/83g.svg";
import { DisabledOpacity } from "components/utils";
import { DrawerHeader } from "components/utils/drawer";
import { useConfirmModal, useQuery, useToastr } from "hooks";
import { Link, useParams } from "react-router-dom";
import { cx, getAnyErrorKey } from "utilities";
import styles from "../RightPanel.module.css";
import localStyles from "./header.module.css";
import { PdfPopover } from "pages/routes/shared/pdfPopover/PdfPopover";
import { useRoutePatchMutation, useRoutes } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { Button } from "components/common/buttonLegacy";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteHook, useSearch } from "../../hooks";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import cuid from "cuid";
import { routesKeys } from "api/routes/keys";

interface Props {
  close: () => void;
}

export const Header = ({ close }: Props) => {
  const { query, updateQuery } = useQuery();
  const { panelId } = query;
  const { tab } = useParams<{ tab: "active" | "archive" }>();
  const patchMutation = useRoutePatchMutation();
  const { data: route, handleMutate } = useRouteHook(panelId);
  const search = useSearch();
  const { refetch: refetchList } = useRoutes(search);
  assertIsDefined(route);

  const toastr = useToastr();
  const confirmModal = useConfirmModal();

  const createCarrierOrderMutation = useMutation(postCarrierOrder, ({ toastr }) => ({
    onMutate: args => {
      return handleMutate(draft => {
        draft.carrierOrder = cuid();
      });
    },
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Utworzono zlecenie dla przewoźnika na trasę "${route.signature}"`,
      });
    },
    onError: (error, _, rollback) => {
      assertIsDefined(rollback);
      rollback(error);
    },
  }));

  const removeDeliveryConfirmationForRouteMutation = useMutation(
    removeDeliveryConfirmationForRoutes,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(routesKeys.route(String(route.id)));
        queryClient.invalidateQueries(routesKeys.routes());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto godziny potwierdzenia dla trasy ${route.signature}`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const deleteMutation = useMutation(deleteRoute, {
    onSuccess: () => {
      updateQuery({ panelId: "" });
      refetchList();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Usunięto trasę ${route.signature}`,
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
    onMutate: () => {
      RouteEditedHandler(route.id);
    },
  });

  const handleDeleteRoute = async () => {
    confirmModal.open({
      text: `Czy na pewno chcesz usunąć trasę ${route.signature}?`,
      callback: () => deleteMutation.mutate(route.id),
    });
  };

  return (
    <DrawerHeader>
      <div className="d-flex align-items-center">
        <Button
          type="button"
          kind="ready"
          size="small"
          width="fit-content"
          className={route.isDraft ? undefined : styles.btnReady}
          onClick={() =>
            patchMutation.mutate({ id: route.id, toUpdate: { isDraft: !route.isDraft } })
          }
          disabled={patchMutation.isLoading || route.status === "SETTLED"}
        >
          <img className="mr-1" alt="" src={completeImg} />
          <span>{route.isDraft ? "Oznacz jako gotowe" : "Gotowe"}</span>
        </Button>
        {route.status === "SETTLED" ? (
          <Link to={`/routes/${route.settledDetails?.id}/settled`} className="blue-link ml-2">
            <img src={cashBlueImg} alt="" />
            <span className="ml-1">Rozliczenie</span>
          </Link>
        ) : (
          <DisabledOpacity
            disabled={route.isDraft}
            title="Najpierw oznacz trasę jako gotową"
            className="d-inline-flex"
          >
            <Link to={`/routes/${panelId}/settle`} className="blue-link ml-2">
              <img src={cashBlueImg} alt="" />
              <span className="ml-1">Rozlicz</span>
            </Link>
          </DisabledOpacity>
        )}
      </div>
      <div className="d-flex align-items-center">
        <PdfPopover route={route} />
        <Tooltip title="Usuń potwierdzenie dostawy klienta dla całej trasy">
          <Button
            disabled={createCarrierOrderMutation.isLoading}
            kind="secondary"
            size="round-s"
            onClick={() =>
              confirmModal.open({
                text: `Teraz usuniesz godziny dostawy potwierdzone przez klienta. Czy na pewno chcesz to zrobić?`,
                confirmText: "Usuń potwierdzenie",
                callback: () =>
                  removeDeliveryConfirmationForRouteMutation.mutate({
                    routes: [route.id],
                  }),
              })
            }
          >
            <img alt="" src={eventBusyIcon} />
          </Button>
        </Tooltip>
        <Tooltip title="Utwórz zlecenie do przewoźnika">
          <Button
            className={cx({
              [localStyles.carrierOrderBtn]: route.carrierOrder,
            })}
            disabled={createCarrierOrderMutation.isLoading}
            kind="secondary"
            size="round-s"
            onClick={() => createCarrierOrderMutation.mutate({ route: route.id })}
          >
            <img alt="" src={createOrderIcon} />
          </Button>
        </Tooltip>
        <Button
          kind="secondary"
          size="round-s"
          as={Link}
          to={`/upholstery-production-orders-plan/list?route=${route.id}&_signature=${route.signature}&_name=${route.name}`}
          title="Wyświetl produkcję"
        >
          <img src={upholsteryIcon} alt="" />
        </Button>
        <span className="border-left border-right px-1 mx-1">
          <Button
            kind="secondary"
            size="round-s"
            as={Link}
            to={
              tab === "active" ? `/routes/creator/${panelId}` : `/routes/creator/${panelId}/archive`
            }
            title="Edytuj trasę"
          >
            {route.status !== "SETTLED" ? (
              <img alt="" src={editIcon} />
            ) : (
              <img alt="" src={eyeIcon} />
            )}
          </Button>
          <Button kind="secondary" size="round-s" title="Usuń trasę" onClick={handleDeleteRoute}>
            <img alt="" src={binIcon} />
          </Button>
          <Button
            kind="secondary"
            size="round-s"
            as={Link}
            to={{ pathname: `/routes/${panelId}/history`, state: { route } }}
            title="Wyświetl historię"
          >
            <img alt="" src={historyImg} />
          </Button>
        </span>
        <Button kind="secondary" size="round-s" onClick={close}>
          <img alt="" src={closeImg} />
        </Button>
      </div>
    </DrawerHeader>
  );
};
