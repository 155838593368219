import { RightPanelSection } from "components/utils/drawer";
import { EmptyNotificationSection } from "./components/EmptyNotificationSection";
import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { useToggle } from "hooks";
import { SendToSpecificAddressModal } from "./components/SendToSpecificAddressModal";

interface Props {
  manufacturingOrder: ExternalManufacturingOrder;
}

export const NotificationsSection = ({ manufacturingOrder }: Props) => {
  const recipientEmailAddressModal = useToggle();

  return (
    <>
      <RightPanelSection title="Wysyłka">
        <EmptyNotificationSection
          manufacturingOrder={manufacturingOrder.id}
          open={recipientEmailAddressModal.open}
        />

        {/* TODO: List of notifications */}
      </RightPanelSection>
      {recipientEmailAddressModal.isOpen && (
        <SendToSpecificAddressModal
          close={recipientEmailAddressModal.close}
          manufacturingOrder={manufacturingOrder.id}
        />
      )}
    </>
  );
};
