import styles from "./Pagination.module.css";
import cx from "classnames";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Table } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiKeyboardArrowRight } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowRight";
import { MdiChevronLeft } from "components/miloDesignSystem/atoms/icons/MdiChevronLeft";

interface Props<T> {
  table: Table<T>;
}

export const Pagination = <T,>({ table }: Props<T>) => {
  const pageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const pageCount = table.getPageCount();

  const hasPreviousPage = pageIndex > 1;
  const hasNextPage = pageSize * pageIndex > pageCount;

  return (
    <div className={cx("d-flex align-items-center px-3 py-2 gap-2", styles.wrapper)}>
      <div className="d-flex align-items-center">
        <IconButton
          variant="blackT"
          onClick={() => table.previousPage()}
          disabled={!hasPreviousPage}
          icon={MdiChevronLeft}
        />

        <IconButton
          variant="blackT"
          icon={MdiKeyboardArrowRight}
          onClick={() => table.nextPage()}
          disabled={hasNextPage}
        />
      </div>

      <div className="d-flex align-items-center gap-1 mr-2">
        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          Wyniki
        </Typography>

        <Typography fontSize="12" fontWeight="700">
          {pageIndex * pageSize - pageSize + 1}-
          {pageIndex * pageSize > pageCount ? pageCount : pageIndex * pageSize}
        </Typography>

        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          / {pageCount}
        </Typography>
      </div>
    </div>
  );
};
