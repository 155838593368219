import React, { InputHTMLAttributes } from "react";
import { BaseIcon } from "../icons/types";

type ErrorMessage = string;

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export enum Size {
  DEFAULT = "default",
  SMALL = "small",
}

export interface TextFieldProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    "onKeyDown" | "onBlur" | "readOnly" | "disabled" | "onFocus" | "placeholder"
  > {
  inputClassName?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  containerClassName?: string;
  endIcon?: React.FunctionComponent<BaseIcon>;
  error?: ErrorMessage | boolean;
  helperText?: string;
  label?: string;
  startIcon?: React.FunctionComponent<BaseIcon>;
  theme?: `${Theme}`;
  size?: `${Size}`;
}
