import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import manufacturerItemsIcon from "assets/images/listAlt.svg";
import { ManufacturerItems } from "routes/externalManufacturing/ManufacturerItems";

export const manufacturerItems: ModuleLink = {
  url: "manufacturer-items",
  label: "Zlecenia produkcyjne",
  icon: manufacturerItemsIcon,
  subSection: <></>,
  routing: ManufacturerItems,
};
