import productionOrdersIcon from "assets/images/189g.svg";
import { Route } from "api/routes/models";
import { routeFileFactory } from "api/routes/calls";
import viewPdfIcon from "assets/images/161.svg";
import printPdfIcon from "assets/images/162.svg";
import printOrderXmlIcon from "assets/images/164.svg";
import viewLabelImg from "assets/images/263.svg";
import optionsImg from "assets/images/41.svg";
import csvDownload from "assets/images/csv_download.svg";
import cx from "classnames";
import { Button } from "components/common";
import { StatusHandler, StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { DOMAIN } from "ENDPOINTS";
import { fileDownloader } from "fileDownloader";
import { useSelector, useStateModal, useToastr, useToggle } from "hooks";
import ReactTooltip from "react-tooltip";
import { getAnyErrorKey } from "utilities";
import styles from "./PdfPopover.module.css";
import { OrderIndex } from "api/orders/models";
import { CreateManufacturerOrProductionOrderModal } from "pages/orders/ordersList/createManufacturerOrProductionOrderModal/CreateManufacturerOrProductionOrderModal";
import { getItemsForCreateManufacturerOrder } from "api/production-orders/calls-manufacturer";
import { CustomerPdfModal } from "./customerPdfModal/CustomerPdfModal";
import { WayfairPdf } from "components/common/wayfairPdf/WayfairPdf";

const formatSignatureForPreview = (signature: string) => signature.replace(/\s|\//g, "-");

type PdfType =
  | "routePdf"
  | "routeProductionPdf"
  | "routeOrdersPdf"
  | "routeItemsSummary"
  | "routePackingSlip"
  | "routePackingSlipGroupedByOrders"
  | "routeDeliveryAmountSummary";

export const PdfPopover = ({ route }: { route: Route }) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const authUser = useSelector(state => state.auth.user!);
  const hasProductionOrdersService = useSelector(
    state => state.partials.configuration.hasProductionOrdersService,
  );
  const createManufacturerOrProductionOrderModal = useStateModal<OrderIndex[]>();
  const customerPdfModal = useToggle();

  async function handleDownloadRoutePdf(
    route: Route,
    type: PdfType,
    helpers: StatusHandlerHelpers,
    search?: string,
  ) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const download = {
      routePdf: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routePdf(route, search || ""),
      routeProductionPdf: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routeProductionPdf(route),
      routeOrdersPdf: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routeOrdersPdf(route),
      routeItemsSummary: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routeItemsSummary(route),
      routePackingSlip: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routePackingSlip(route),
      routePackingSlipGroupedByOrders: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routePackingSlipGroupedByOrders(route),
      routeDeliveryAmountSummary: (route: Pick<Route, "id" | "signature">) =>
        routeFileFactory.routeDeliveryAmountSummary(route),
    }[type];

    const { url, name } = download(route);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
      customerPdfModal.close();
    } else {
      tstr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  async function handleCreateManufacturerOrder(
    listOfOrdersId: number[],
    helpers: StatusHandlerHelpers,
  ) {
    if (!listOfOrdersId.length) {
      return toastr.open({
        type: "warning",
        title: "Nie można stworzyć zleceń",
        text: "Dodaj co najmniej jedno zamówienie",
      });
    }
    helpers.startFetching();
    const [payload, error] = await getItemsForCreateManufacturerOrder({ orders: listOfOrdersId });
    helpers.stopFetching();
    if (payload) {
      createManufacturerOrProductionOrderModal.open(payload);
    } else if (error) {
      toastr.open({
        type: "warning",
        title: "Nie można stworzyć zleceń",
        text: getAnyErrorKey(error),
      });
    }
  }

  async function handleRouteSettlementCsvDownload(route: Route, helpers: StatusHandlerHelpers) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const { url, name } = routeFileFactory.routeSettlementCSV(route);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "csv",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  async function handleDownloadRouteXml(route: Route, helpers: StatusHandlerHelpers) {
    const tstr = downloadFeedbackToastr.open({ type: "xml" });
    helpers.startFetching();
    const { url, name } = routeFileFactory.routeOrdersXml(route);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "xml",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  async function handleCsvDownload(route: Route, helpers: StatusHandlerHelpers) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const { url, name } = routeFileFactory.routeOrdersCSV(route);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "csv",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  return (
    <>
      <Button
        kind="secondary"
        size="round-s"
        className={styles.moreOptions}
        data-for={String(route.id)}
        data-event="click"
        data-event-off="mouseleave"
        data-tip="2"
      >
        <img src={optionsImg} alt="" />
      </Button>
      <ReactTooltip
        className={styles.tooltip}
        id={String(route.id)}
        type="light"
        delayHide={400}
        effect="solid"
        place="bottom"
      >
        <div className={styles.tooltipContainer}>
          <div className={cx(" text-color-grey", styles.label)}>Dokumenty trasy</div>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/route-pdf/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}?user=${authUser.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd PDF trasy</span>
          </a>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routePdf", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz PDF trasy</span>
              </div>
            )}
          </StatusHandler>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}utils/packing-slip/route/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd listu przewozowego</span>
          </a>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routePackingSlip", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz list przewozowy</span>
              </div>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routeDeliveryAmountSummary", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz PDF z podsumowaniem kosztów dostawy</span>
              </div>
            )}
          </StatusHandler>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}utils/packing-slip-grouped-by-orders/route/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd listu przewozowego z zamówieniami</span>
          </a>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() =>
                  handleDownloadRoutePdf(route, "routePackingSlipGroupedByOrders", helpers)
                }
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz list przewozowy z zamówieniami</span>
              </div>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routeOrdersPdf", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz PDF szczegółów zamówień</span>
              </div>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <div
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
                onClick={() => handleRouteSettlementCsvDownload(route, helpers)}
              >
                <img alt="Pobierz" src={csvDownload} />
                <span>Pobierz CSV z rozliczeniem trasy</span>
              </div>
            )}
          </StatusHandler>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/warehouse-packages/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewLabelImg} />
            <span>Podgląd etykiet magazynowych dla trasy</span>
          </a>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/receipt-pdf/${route.id}/cz/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd PDF dokumentu Czechy</span>
          </a>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/receipt-pdf/${route.id}/sk/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd PDF dokumentu Słowacja</span>
          </a>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRouteXml(route, helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printOrderXmlIcon} />
                <span>Pobierz XML trasy</span>
              </div>
            )}
          </StatusHandler>

          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/cmr-pdf/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd CMR trasy</span>
          </a>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/cmr-pdf-per-order/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd CMR zamówień</span>
          </a>

          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/trading-document-pdf/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd PDF polecenia księgowania</span>
          </a>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/driver-pdf/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}?user=${authUser.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd PDF dla kierowcy</span>
          </a>
          <div className={styles.pdf} onClick={customerPdfModal.open}>
            <img alt="" src={viewPdfIcon} />
            <span>Pobierz PDF trasy na kontrahenta</span>
          </div>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routeItemsSummary", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz PDF: lista indeksów trasy</span>
              </div>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleCsvDownload(route, helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img src={csvDownload} alt="" />
                <span>Pobierz CSV: lista zamówień trasy</span>
              </div>
            )}
          </StatusHandler>
          <WayfairPdf id={route.id} type="route" />
          <div className={cx(" text-color-grey", styles.label)}>Dokumenty produkcyjne</div>

          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/production-pdf/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd produkcyjnego PDFa trasy</span>
          </a>
          <StatusHandler>
            {helpers => (
              <div
                onClick={() => handleDownloadRoutePdf(route, "routeProductionPdf", helpers)}
                className={cx(styles.pdf, { [styles.disabled]: helpers.isFetching })}
              >
                <img alt="" src={printPdfIcon} />
                <span>Pobierz produkcyjny PDF trasy</span>
              </div>
            )}
          </StatusHandler>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}utils/labels/route/${route.id}/${formatSignatureForPreview(
              route.signature,
            )}`}
            target="_blank"
          >
            <img alt="" src={viewLabelImg} />
            <span>Podgląd etykiet zamówień</span>
          </a>
          <div className={cx(" text-color-grey", styles.label)}>Formularze dla kierowcy</div>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/return-items-template/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd formularza zwrotów z trasy</span>
          </a>
          <a
            className={styles.pdf}
            rel="noopener noreferrer"
            href={`${DOMAIN}routes/driver-cash-bills/${route.id}`}
            target="_blank"
          >
            <img alt="" src={viewPdfIcon} />
            <span>Podgląd dokumentów KP - Niemcy</span>
          </a>
          {hasProductionOrdersService && (
            <StatusHandler>
              {helpers => (
                <div className={styles.createOrder}>
                  <Button
                    type="button"
                    kind="secondary-stroke"
                    size="medium"
                    className="fs-14"
                    disabled={helpers.isFetching}
                    onClick={() =>
                      handleCreateManufacturerOrder(
                        route.orders.map(el => el.id),
                        helpers,
                      )
                    }
                  >
                    <img src={productionOrdersIcon} alt="" className="mr-1" />
                    <span>Utwórz zlecenia produkcyjne</span>
                  </Button>
                </div>
              )}
            </StatusHandler>
          )}
        </div>
      </ReactTooltip>
      <CreateManufacturerOrProductionOrderModal modal={createManufacturerOrProductionOrderModal} />

      {customerPdfModal.isOpen && (
        <CustomerPdfModal
          isOpen={customerPdfModal.isOpen}
          close={customerPdfModal.close}
          route={route}
        />
      )}
    </>
  );
};
