import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "../../externalManufacturerPanel/manufacturerItemsList/utils/getSearch";
import { getTabs } from "../../externalManufacturerPanel/manufacturerItemsList/utils/getTabs";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useOrderLineItemColumns } from "./useOrderLineItemsColumns";
import styles from "../../externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { cx } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import {
  ExternalManufacturerItemsTab,
  OrderLineItemListItem,
} from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
// import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
// import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
// import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
// import { TableProps } from "components/miloDesignSystem/molecules/table/types";
// import { UUID } from "api/types";
// import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
// import { useState } from "react";
// import { HighlightedRow } from "api/other/models";
// import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
// import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
// import { SvgWrapper } from "components/miloDesignSystem/atoms/icons/SvgWrapper";
// import { BaseIcon } from "components/miloDesignSystem/atoms/icons/types";
// import { Typography } from "components/miloDesignSystem/atoms/typography";
// import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";

export const OrderLineItems = ({
  match,
}: RouteComponentProps<{ tab: ExternalManufacturerItemsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: manufacturerLineItems,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = externalManufacturingActions.useOrderLineItems(search);
  const columns = useOrderLineItemColumns();
  // const bulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateLineItems();

  // const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `external-manufacturing/manufacturer-line-items`,
          urlSpan: "list",
        }}
        viewLabel="EXTERNAL_MANUFACTURER_LINE_ITEMS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
        <DrawerHelpers name="externalManufacturerItems">
          {drawerHelpers => (
            <>
              <Table<OrderLineItemListItem>
                rows={manufacturerLineItems}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                onRowClick={(rowId, e) => {
                  drawerHelpers.togglePanel(rowId);
                  // const index = manufacturerLineItems.findIndex(e => e.id === rowId);
                  // handleMultipleRowsHighlight(
                  //   e,
                  //   highlightedRows,
                  //   rowId,
                  //   index,
                  //   setHighlightedRows,
                  //   () => drawerHelpers.togglePanel(rowId),
                  //   manufacturerLineItems,
                  // );
                }}
                overrides={row => {
                  const className = getTablePropsBasedOnDrawerHelpers(drawerHelpers).overrides;

                  // if (highlightedRows.find(r => r.id === row.id)) {
                  //   return {
                  //     row: {
                  //       className: styles.selected,
                  //     },
                  //   };
                  // }
                  if (row.isCancelled && className) {
                    return {
                      row: {
                        className: cx(styles.cancelledRow, className(row).row?.className),
                      },
                    };
                  }
                  if (className)
                    return {
                      row: {
                        className: className(row).row?.className,
                      },
                    };
                  return {};
                }}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
        {/* <TableToolbar
          close={() => {
            setHighlightedRows([]);
          }}
          numberOfSelectedItems={highlightedRows.length}
        >
          <Tooltip title="Ustaw priorytet A">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate({
                  ids: highlightedRows.map(e => String(e.id)),
                  priority: 2,
                });
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[2].color}>
                  {priorityDict[2].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet B">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate({
                  ids: highlightedRows.map(e => String(e.id)),
                  priority: 1,
                });
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[1].color}>
                  {priorityDict[1].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet C">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate({
                  ids: highlightedRows.map(e => String(e.id)),
                  priority: 0,
                });
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[0].color}>
                  {priorityDict[0].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Potwierdź datę odbioru">
            <IconButton
              icon={MdiCheck}
              variant="whiteT"
              onClick={() => {
                bulkUpdateMutation.mutate({
                  ids: highlightedRows.map(e => String(e.id)),
                  hasConfirmedDeliveryDate: true,
                });
              }}
            />
          </Tooltip>
          <IconButton icon={MdiAdd} variant="whiteT" />
          <IconButton icon={MdiAdd} variant="whiteT" />
          <IconButton icon={MdiAdd} variant="whiteT" />
        </TableToolbar> */}
      </div>
    </PageWrapper>
  );
};
