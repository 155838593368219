import styles from "../RightPanel.module.css";
import cx from "classnames";
import { RightPanelSection } from "components/utils/drawer";
import {
  expectedPaymentFormDict,
  invoicePaymentStatusDict,
  invoicePaymentStatusToPick,
  TradingDocument,
} from "api/trading-documents/models";
import darkPlusIcon from "assets/images/darkPlus.svg";
import binIcon from "assets/images/delete_red.svg";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { AsyncInput, Checkbox, Select } from "components/utils";
import { noop } from "utilities";
import { EditDiscountSection } from "./EditDiscountSection";
import { Button } from "components/common";
import { useToggle } from "hooks";
import { AddPurchaseInvoicePosition } from "./components/AddPurchaseInvoicePosition";
import { getMaximumDisplayPosition } from "./utils/getMaximumDisplayPosition";
import { EditPurchaseInvoicePosition } from "./EditPurchaseInvoicePosition";
import { useState } from "react";
import { DeletePurchaseInvoicePosition } from "./DeletePurchaseInvoicePosition";
import {
  usePatchTradingDocumentMutation,
  useUpdateTradingDocumentOptionsForPurchaseImport,
} from "api/trading-documents/hooks";
import { EditAmountWithTaxSection } from "./EditAmountWithTaxSection";
import { useRemoveTradingDocumentItem } from "./hooks/useRemoveTradingDocumentItem";
import { TotalAmountSection } from "./TotalAmountSection";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  enableAddingPosition?: boolean;
  isPurchaseImport?: boolean;
  showTransactionNumber?: boolean;
  tradingDocument: TradingDocument;
}

export const ItemsSummarySection = ({
  enableAddingPosition = false,
  isPurchaseImport = false,
  showTransactionNumber = true,
  tradingDocument,
}: Props) => {
  const addPurchaseInvoicePosition = useToggle();
  const [isDisplayPositionEdited, setIsDisplayPositionEdited] = useState(false);
  const priceInWords = tradingDocument.amountSummary.totalWithTaxInWords;
  const removeTradingDocumentItemMutation = useRemoveTradingDocumentItem(tradingDocument.id);
  const paymentFormMutation = useUpdateTradingDocumentOptionsForPurchaseImport();
  const paymentStatusMutation = usePatchTradingDocumentMutation();
  const expectedPaymentFormMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection>
      <div className="pb-3">
        <div
          className={cx(styles.tableHeader, {
            [styles.tableHeaderOrderWithSignature]: tradingDocument.invoiceType === "SALES",
            [styles.tableHeaderOrder]:
              tradingDocument.invoiceType !== "SALES" && !isDisplayPositionEdited,
            [styles.tableHeaderOrderWithEditableDisplayPosition]: isDisplayPositionEdited,
          })}
        >
          <div>#</div>
          <div>nazwa towaru</div>
          {tradingDocument.invoiceType === "SALES" && <div>zamówienie</div>}
          {tradingDocument.invoiceType === "SALES" && <div>rabat</div>}
          {tradingDocument.invoiceType === "SALES" && (
            <div className="d-flex align-items-center justify-content-end">w. rabatu</div>
          )}
          <div className="d-flex align-items-center justify-content-end">liczba</div>
          <div
            className={cx({
              "d-flex align-items-center justify-content-end":
                tradingDocument.invoiceType !== "SALES",
            })}
          >
            jedn.
          </div>
          {tradingDocument.invoiceType !== "SALES" && (
            <div className="d-flex align-items-center justify-content-end">c. netto</div>
          )}
          {tradingDocument.invoiceType === "SALES" && (
            <div className="d-flex align-items-center justify-content-center">c. brutto</div>
          )}
          {tradingDocument.invoiceType !== "SALES" && (
            <div className="d-flex align-items-center justify-content-end">c. brutto</div>
          )}
          {tradingDocument.invoiceType === "SALES" && (
            <div className="d-flex align-items-center justify-content-center">c. brutto po r.</div>
          )}
          <div
            className={cx("d-flex align-items-center", {
              "justify-content-end": tradingDocument.invoiceType !== "SALES",
              "justify-content-center": tradingDocument.invoiceType === "SALES",
            })}
          >
            VAT
          </div>
          {tradingDocument.invoiceType !== "SALES" && (
            <div className="d-flex align-items-center justify-content-end">war. netto</div>
          )}
          {tradingDocument.invoiceType === "SALES" && (
            <div className="d-flex align-items-center justify-content-end">w. brutto</div>
          )}
        </div>
        <div>
          {tradingDocument.items.map(item =>
            item.tradingDocumentItems.map((tradingDocumentItem, index) => (
              <div
                className={cx(styles.tableRow, {
                  [styles.tableRowOrderWithEditableDisplayPosition]: isDisplayPositionEdited,
                  [styles.tableRowOrderForSales]: tradingDocument.invoiceType === "SALES",
                  [styles.tableRowOrderWithSignature]: tradingDocument.invoiceType === "SALES",
                  [styles.tableRowOrder]:
                    tradingDocument.invoiceType !== "SALES" && !isDisplayPositionEdited,
                })}
                key={index}
              >
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="#"
                    inputType="number"
                    itemId={item.id}
                    setIsDisplayPositionEdited={setIsDisplayPositionEdited}
                    target={<div className="fw-800">{tradingDocumentItem.displayPosition}*.</div>}
                    targetKey="displayPosition"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.displayPosition}
                  />
                ) : (
                  <div>{tradingDocumentItem.displayPosition}.</div>
                )}
                <div className="d-flex align-items-center gap-1">
                  {enableAddingPosition &&
                  tradingDocumentItem.addedMethod &&
                  tradingDocumentItem.addedMethod === "MANUALLY" ? (
                    <>
                      <EditPurchaseInvoicePosition
                        inputPlaceholder="nazwa"
                        inputType="string"
                        itemId={item.id}
                        target={
                          <div className={styles.productName}>{tradingDocumentItem.name}</div>
                        }
                        targetKey="name"
                        tradingDocumentId={tradingDocument.id}
                        tradingDocumentItemId={tradingDocumentItem.id}
                        value={tradingDocumentItem.name}
                      />
                      <DeletePurchaseInvoicePosition
                        itemId={item.id}
                        tradingDocumentId={tradingDocument.id}
                        tradingDocumentItemId={tradingDocumentItem.id}
                        tradingDocumentItemName={tradingDocumentItem.name}
                      />
                    </>
                  ) : !enableAddingPosition &&
                    tradingDocument.invoiceType === "SALES" &&
                    tradingDocument.status !== "CONFIRMED" ? (
                    <div className="d-flex align-items-center justify-content-between gap-1">
                      <div className={styles.productName}>{tradingDocumentItem.name}</div>
                      <div className="d-flex justify-content-end">
                        <Button
                          kind="transparent-black"
                          onClick={() =>
                            removeTradingDocumentItemMutation.mutate({
                              id: tradingDocumentItem.id,
                              itemId: item.id,
                              name: tradingDocumentItem.name,
                              tradingDocumentId: tradingDocument.id,
                            })
                          }
                          size="square-s"
                        >
                          <div className="btnBase btnBaseSmall btnBase16">
                            <img alt="" src={binIcon} />
                          </div>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.productName}>{tradingDocumentItem.name}</div>
                  )}
                </div>
                {tradingDocument.invoiceType === "SALES" && (
                  <div>
                    {item.order && item.order.signature.length > 0 ? item.order?.signature : "--"}
                  </div>
                )}
                {tradingDocument.invoiceType === "SALES" && (
                  <EditDiscountSection
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItem={tradingDocumentItem}
                  />
                )}
                {tradingDocument.invoiceType === "SALES" && (
                  <div className="fw-700 d-flex justify-content-end align-items-center">
                    {tradingDocumentItem.discountAmount !== undefined ? (
                      <FinanceAmountFormat value={tradingDocumentItem.discountAmount} />
                    ) : (
                      "--"
                    )}
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocument.invoiceType !== "SALES" &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="liczba"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        {tradingDocumentItem.quantity}
                      </div>
                    }
                    targetKey="quantity"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.quantity}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    {tradingDocumentItem.quantity}
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="jednostka"
                    inputType="string"
                    itemId={item.id}
                    target={<div>{tradingDocumentItem.unit}</div>}
                    targetKey="unit"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.unit}
                  />
                ) : (
                  <div className="d-flex justify-content-end">{tradingDocumentItem.unit}</div>
                )}
                {enableAddingPosition &&
                tradingDocument.invoiceType !== "SALES" &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="cena netto"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        <FinanceAmountFormat value={tradingDocumentItem.amountWithoutTax} />
                      </div>
                    }
                    targetKey="amountWithoutTax"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.amountWithoutTax}
                  />
                ) : (
                  tradingDocument.invoiceType !== "SALES" && (
                    <div className="d-flex align-items-center justify-content-end">
                      <FinanceAmountFormat value={tradingDocumentItem.amountWithoutTax} />
                    </div>
                  )
                )}
                {tradingDocument.invoiceType === "SALES" ? (
                  <EditAmountWithTaxSection
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItem={tradingDocumentItem}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat value={tradingDocumentItem.amountWithTax} />
                  </div>
                )}
                {tradingDocument.invoiceType === "SALES" && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat value={tradingDocumentItem.amountWithTaxAfterDiscount} />
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="vat"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        {tradingDocumentItem.vatRate}%
                      </div>
                    }
                    targetKey="vatRate"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.vatRate}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    {tradingDocumentItem.vatRate}%
                  </div>
                )}
                {tradingDocument.invoiceType !== "SALES" && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat
                      value={(
                        tradingDocumentItem.quantity * tradingDocumentItem.amountWithoutTax
                      ).toFixed(2)}
                    />
                  </div>
                )}
                {tradingDocument.invoiceType === "SALES" && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat
                      value={tradingDocumentItem.totalAmountWithTaxAfterDiscount}
                    />
                  </div>
                )}
              </div>
            )),
          )}
        </div>
        {enableAddingPosition && (
          <>
            <div className="py-2 d-flex align-items-center justify-content-end">
              <Button kind="create" onClick={addPurchaseInvoicePosition.open}>
                <div className="btnBase btnBaseSmall">
                  <img alt="Utwórz" src={darkPlusIcon} />
                  <span>Dodaj pozycję</span>
                </div>
              </Button>
            </div>
            {addPurchaseInvoicePosition.isOpen && (
              <AddPurchaseInvoicePosition
                close={addPurchaseInvoicePosition.close}
                maxDisplayPosition={getMaximumDisplayPosition(
                  tradingDocument.items[0].tradingDocumentItems,
                )}
                tradingDocumentOrder={tradingDocument.items[0].id}
              />
            )}
          </>
        )}
        <div className="pt-4">
          <div className={cx(styles.tableHeader, styles.tableHeaderOrderSummary)}>
            <div />
            <div className="d-flex align-items-center justify-content-end">kwota do zapłaty</div>
            <div className="d-flex align-items-center justify-content-end" />
            <div>VAT</div>
            <div className="d-flex align-items-center justify-content-end">wartość netto</div>
            <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
            <div className="d-flex align-items-center justify-content-end">Wartość brutto</div>
          </div>
          {tradingDocument.amountSummaryPerVatRate.map((summary, index) => (
            <div className={cx(styles.tableRow, styles.tableRowOrderSummary)} key={index}>
              <div />
              <div className="d-flex align-items-center justify-content-end">
                {index === 0 && (
                  <div className="d-flex align-items-center">
                    <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                )}
              </div>
              {index === 0 ? (
                <div className="d-flex align-items-center justify-content-end text-grey-35">
                  w tym:
                </div>
              ) : (
                <div />
              )}
              <div>{summary.vatRate}%</div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithoutTax && <FinanceAmountFormat value={summary.totalWithoutTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalTax && <FinanceAmountFormat value={summary.totalTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithTax && <FinanceAmountFormat value={summary.totalWithTax} />}
              </div>
            </div>
          ))}
          <div className={cx(styles.tableRow, styles.tableRowOrderSummary)}>
            <div />
            <div />
            <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
            <div />
            <div className="d-flex align-items-center justify-content-end" />
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalTax} />
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
              </strong>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pt-3 row">
          <div className="pt-1 col-5  ">
            <div className="pb-3">
              {isPurchaseImport ? (
                <InfoLabel title="forma płatności">
                  {tradingDocument.status === "CONFIRMED" ? (
                    <>
                      {tradingDocument.expectedPaymentForm &&
                      tradingDocument.expectedPaymentForm.length > 0 ? (
                        <span
                          className={cx("body-14-500", {
                            [styles.fontStyle]:
                              tradingDocument.expectedPaymentForm !== "CASH" &&
                              tradingDocument.expectedPaymentForm !== "ONLINE",
                          })}
                        >
                          {tradingDocument.expectedPaymentForm === "CASH" ||
                          tradingDocument.expectedPaymentForm === "ONLINE"
                            ? expectedPaymentFormDict[tradingDocument.expectedPaymentForm]
                            : tradingDocument.expectedPaymentForm}
                        </span>
                      ) : (
                        <EmptyValue className="italic" fontSize="14" fontWeight="500" />
                      )}
                    </>
                  ) : (
                    <AsyncInput
                      disabled={false}
                      look="common"
                      onChange={value =>
                        paymentFormMutation.mutate({
                          id: tradingDocument.id,
                          expectedPaymentForm: value,
                        })
                      }
                      overwrites={{
                        input: {
                          className: cx(styles.formHeight, {
                            [styles.fontStyle]:
                              tradingDocument.expectedPaymentForm !== "CASH" &&
                              tradingDocument.expectedPaymentForm !== "ONLINE",
                          }),
                        },
                        floatingLabel: { className: styles.inputTextLabel },
                      }}
                      value={
                        tradingDocument.expectedPaymentForm === "CASH" ||
                        tradingDocument.expectedPaymentForm === "ONLINE"
                          ? expectedPaymentFormDict[tradingDocument.expectedPaymentForm]
                          : tradingDocument.expectedPaymentForm
                      }
                    />
                  )}
                </InfoLabel>
              ) : (
                <InfoLabel title="forma płatności">
                  {tradingDocument.type !== "RECEIPT" ? (
                    <div>
                      {tradingDocument.invoiceType !== "SALES" &&
                      tradingDocument.expectedPaymentForm &&
                      Boolean(tradingDocument.expectedPaymentForm.length) ? (
                        <span
                          className={cx("body-14-500", {
                            [styles.fontStyle]:
                              tradingDocument.expectedPaymentForm !== "CASH" &&
                              tradingDocument.expectedPaymentForm !== "ONLINE",
                          })}
                        >
                          {tradingDocument.expectedPaymentForm === "CASH" ||
                          tradingDocument.expectedPaymentForm === "ONLINE"
                            ? expectedPaymentFormDict[tradingDocument.expectedPaymentForm]
                            : tradingDocument.expectedPaymentForm}
                        </span>
                      ) : tradingDocument.invoiceType === "SALES" &&
                        tradingDocument.expectedPaymentForm &&
                        Boolean(tradingDocument.expectedPaymentForm.length) ? (
                        <AsyncInput
                          disabled={expectedPaymentFormMutation.isLoading}
                          inProgress={expectedPaymentFormMutation.isLoading}
                          onChange={value =>
                            expectedPaymentFormMutation.mutate({
                              id: tradingDocument.id,
                              expectedPaymentForm: value,
                            })
                          }
                          overwrites={{
                            container: { className: styles.input },
                            input: {
                              className: cx(styles.input, {
                                italic:
                                  tradingDocument.expectedPaymentForm !== "CASH" &&
                                  tradingDocument.expectedPaymentForm !== "ONLINE",
                              }),
                            },
                          }}
                          placeholder="forma płatności"
                          value={
                            tradingDocument.expectedPaymentForm === "CASH" ||
                            tradingDocument.expectedPaymentForm === "ONLINE"
                              ? expectedPaymentFormDict[tradingDocument.expectedPaymentForm]
                              : tradingDocument.expectedPaymentForm
                          }
                        />
                      ) : (
                        <EmptyValue className="italic" fontSize="14" fontWeight="500" />
                      )}
                    </div>
                  ) : (
                    <EmptyValue className="italic" fontSize="14" fontWeight="500" />
                  )}
                </InfoLabel>
              )}
              <InfoLabel title="wystawił(/a)">
                <Typography fontSize="14" fontWeight="700" noWrap>
                  {Boolean(tradingDocument.signatureOfAnAuthorizedPerson.length)
                    ? tradingDocument.signatureOfAnAuthorizedPerson
                    : "---"}
                </Typography>
              </InfoLabel>
              {tradingDocument.invoiceType === "SALES" && (
                <div className="d-flex align-items-center gap-2 pb-2">
                  <Checkbox
                    checked={tradingDocument.isManagedManually}
                    className="fw-600 mt-1"
                    disabled={true}
                    label="Manualnie edytowana faktura"
                    name="isManagedManually"
                    onChange={noop}
                    overwrites={{ label: { className: styles.manuallyEditedInvoice } }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-7 pl-4 overflow-hidden">
            <TotalAmountSection tradingDocument={tradingDocument} />

            <div
              className={cx(
                styles.verbalNotationWrapper,
                "d-flex flex-column justify-content-center align-items-end pt-2",
              )}
            >
              <div className="d-flex align-items-center justify-content-end body-14-500">
                Kwota słownie:
              </div>
              <div className="d-flex justify-content-end text-right w-100">
                {priceInWords && priceInWords.length > 0 ? (
                  <div className="d-flex flex-column align-items-end w-100">
                    <strong className="body-14 fw-700 d-inline-block nowrap text-truncate w-100">
                      {priceInWords}
                    </strong>
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                ) : (
                  <span className="fw-500 italic ">Nie udało się odczytać kwoty </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <InfoLabel title="zapłacono">
          {tradingDocument.invoiceType !== "PURCHASE" && tradingDocument.invoiceType !== "SALES" ? (
            <Typography fontSize="16" fontWeight="700">
              {invoicePaymentStatusDict[tradingDocument.paymentStatus]}
            </Typography>
          ) : (
            <Select
              disabled={paymentStatusMutation.isLoading}
              items={invoicePaymentStatusToPick}
              onChange={paymentStatus => {
                if (paymentStatus) {
                  paymentStatusMutation.mutate({
                    id: tradingDocument.id,
                    paymentStatus: paymentStatus.id,
                  });
                }
              }}
              selectedItem={tradingDocument.paymentStatus ?? null}
              size="small"
            />
          )}
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
