import { Table } from "@tanstack/react-table";
import { Assign } from "utility-types";
import { NoResults } from "components/utils/noResults";
import { CommonError } from "components/utils";
import { TableProps } from "../../types";
import { Body } from "../body/Body";
import { TableLoader } from "../tableLoader/TableLoader";

export type BodyProps<T> = Assign<
  { table: Table<T>; isLoading: boolean; containerRef: React.RefObject<HTMLDivElement> },
  Pick<TableProps<T>, "onRowClick" | "error" | "isLoading" | "uiSchema" | "columns" | "overrides">
>;

export const BodyWrapper = <T,>({
  columns,
  containerRef,
  table,
  onRowClick,
  overrides,
  error,
  isLoading,
  uiSchema,
}: BodyProps<T>) => {
  const rows = table.getRowModel().rows;

  const noResults = !rows.length && !isLoading && !error;
  if (noResults)
    return (
      <NoResults
        overwrites={{
          textWrapper: { className: "mb-0" },
          subHeader: { className: "mb-0" },
          background: { className: "h-100 mt-3" },
          img: { style: { height: "120px" } },
        }}
      />
    );

  if (error)
    return (
      <div className="mt-4">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (isLoading) {
    return (
      <TableLoader
        containerRef={containerRef}
        table={table}
        uiSchema={uiSchema}
        columns={columns}
      />
    );
  }

  return <Body table={table} uiSchema={uiSchema} onRowClick={onRowClick} overrides={overrides} />;
};
