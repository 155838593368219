import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { OrderStatusLabel } from "../components/OrderStatusLabel";
import { OrderStatus } from "api/external-manufacturing/models";

interface Props {
  orderStatus: OrderStatus;
  signature: string;
}

export const TitleSection = ({ orderStatus, signature }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="26" fontWeight="700">
          {signature}
        </Typography>
        <OrderStatusLabel status={orderStatus} />
      </div>
    </RightPanelSection>
  );
};
