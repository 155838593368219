import { ButtonHTMLAttributes, ForwardedRef, PropsWithChildren } from "react";
import styles from "./Button.module.css";
import cx from "classnames";
import { Typography } from "../typography";
import React from "react";
import { TypographyProps } from "../typography/types";
import { ColorPalette } from "../colorsPalette";
import { BaseIcon } from "../icons/types";
import { MdiSchedule } from "../icons/MdiSchedule";

const DEFAULT_ICON_SIZE = "16";

type ButtonSize = "large" | "medium" | "small";

type ButtonVariant =
  | "deepPurple"
  | "danger"
  | "gray"
  | "black"
  | "outline"
  | "success"
  | "transparent";

type ButtonTheme = "light" | "dark";

const sizeSelector: Record<ButtonSize, TypographyProps["fontSize"]> = {
  large: "16",
  medium: "12",
  small: "10",
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  endIcon?: React.FunctionComponent<BaseIcon> | JSX.Element;
  isLoading?: boolean;
  size: ButtonSize;
  startIcon?: React.FunctionComponent<BaseIcon> | JSX.Element;
  variant: ButtonVariant;
  theme?: ButtonTheme;
}

const getVariantStyling = (theme: string, variant: string) => {
  return `${theme}-${variant}`;
};

const iconColorDictionary: Record<ButtonVariant, Record<ButtonTheme, ColorPalette>> = {
  deepPurple: {
    light: "neutralWhite100",
    dark: "deepPurple300",
  },
  danger: {
    light: "neutralWhite100",
    dark: "danger400",
  },
  gray: {
    light: "neutralBlack88",
    dark: "neutralWhite100",
  },
  black: {
    light: "neutralWhite100",
    dark: "neutralBlack100",
  },
  outline: {
    light: "neutralBlack88",
    dark: "success400",
  },
  success: {
    light: "success900",
    dark: "success900",
  },
  transparent: {
    light: "neutralBlack88",
    dark: "neutralWhite100",
  },
};

export const Button = React.forwardRef(
  (
    {
      children,
      className,
      disabled = false,
      endIcon: EndIcon,
      isLoading,
      size,
      startIcon: StartIcon,
      variant,
      theme = "light",
      ...rest
    }: PropsWithChildren<Props>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const disabledStyle = disabled ? theme + "-disabled" : "";
    if (isLoading)
      return (
        <LoadingButton
          children={children}
          className={className}
          disabled={disabled}
          isLoading={isLoading}
          size={size}
          startIcon={StartIcon}
          variant={variant}
          theme={theme}
          {...rest}
        />
      );
    return (
      <button
        className={cx(
          styles.button,
          styles[size],
          styles[getVariantStyling(theme, variant)],
          className,
          { [styles[disabledStyle]]: variant !== "success" },
        )}
        disabled={disabled}
        type="button"
        ref={ref}
        {...rest}
      >
        {StartIcon && typeof StartIcon === "function" ? (
          <StartIcon color={iconColorDictionary[variant][theme]} size={DEFAULT_ICON_SIZE} />
        ) : StartIcon ? (
          StartIcon
        ) : null}
        <Typography color="inherit" fontSize={sizeSelector[size]} fontWeight="700">
          {children}
        </Typography>
        {EndIcon && typeof EndIcon === "function" ? (
          <EndIcon color={iconColorDictionary[variant][theme]} size={DEFAULT_ICON_SIZE} />
        ) : EndIcon ? (
          EndIcon
        ) : null}
      </button>
    );
  },
);

const LoadingButton = ({
  children,
  className,
  disabled,
  isLoading,
  size,
  startIcon: StartIcon,
  variant,
  theme = "light",
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <button
      className={cx(styles.button, styles[size], styles[getVariantStyling(theme, variant)])}
      disabled={isLoading}
      style={{ cursor: "wait" }}
      {...rest}
    >
      {StartIcon && typeof StartIcon === "function" ? (
        <StartIcon color={iconColorDictionary[variant][theme]} size={DEFAULT_ICON_SIZE} />
      ) : StartIcon ? (
        StartIcon
      ) : null}
      <Typography
        color="inherit"
        className={styles[`${size}Text`]}
        fontSize={sizeSelector[size]}
        fontWeight="700"
      >
        {children}
      </Typography>
      <MdiSchedule size={DEFAULT_ICON_SIZE} color={iconColorDictionary[variant][theme]} />
    </button>
  );
};
