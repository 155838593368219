import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { useShippingShipmentsColumns } from "./useShippingShipmentsColumns";
import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";

export const ShippingShipments = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);

  const {
    data: carrierOrders,
    error,
    isLoading,
    pagination,
  } = shippingActions.useShippingShipments(search);

  const columns = useShippingShipmentsColumns();

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          viewLabel="SHIPPING_SHIPMENTS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <DrawerHelpers name="shippingShipments">
            {drawerHelpers => (
              <>
                <Table<Shipment>
                  rows={carrierOrders}
                  columns={columns}
                  onPaginationChange={paginationState => {
                    updateQuery({ ...query, page: paginationState.pageIndex });
                  }}
                  isLoading={isLoading}
                  error={error}
                  pagination={pagination}
                  uiSchema={mainListUiSchema}
                  {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
                />
              </>
            )}
          </DrawerHelpers>
        </div>
      </PageWrapper>
    </>
  );
};
