import { usePickingLineItemsDetails } from "api/wms/hooks";
import { NormalizedPickingPackageGroup, PickingPackageStatus, statusDict } from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { useMemo } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import pickingIcon from "assets/images/picking.svg";
import styles from "pages/wms/shared/Wms.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { countryCodesDict } from "CONSTANTS";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useLineItemsDetailsColumns } from "./useLineItemsDetailsColumns";
import { cx } from "utilities";
import { useDownloadPickingLineItemPdf } from "../../hooks/useDownloadPickingLineItemPdf";

interface Props {
  close: () => void;
  lineItemId: string;
}

export const pickingPackageStatusDict: Record<
  PickingPackageStatus,
  { label: string; variant: "outline" | "warning" | "success" }
> = {
  IN_STOCK: { label: "Na stanie", variant: "outline" },
  ON_THE_WAY: { label: "W drodze", variant: "warning" },
  PICKED_UP: { label: "Podniesione", variant: "warning" },
  ON_RAMP: { label: "Na rampie", variant: "success" },
};

export const LineItemsDetailsModal = ({ close, lineItemId }: Props) => {
  const { data: lineItem, error, isLoading, isFetching } = usePickingLineItemsDetails(lineItemId);
  const columns = useLineItemsDetailsColumns();
  const handleDownloadPickingLineItemPdf = useDownloadPickingLineItemPdf();
  const normalizedData: NormalizedPickingPackageGroup[] = useMemo(() => {
    if (!lineItem) return [];
    return lineItem.packagesGroups.map(packageGroup => {
      const subRows: NormalizedPickingPackageGroup[] = (packageGroup.packages || []).map(
        packageRow => {
          return {
            id: packageRow.id,
            name: packageRow.name,
            location: packageRow.location,
            pickedUpAt: packageRow.pickedUpAt,
            placeOnRampAt: packageRow.placeOnRampAt,
            status: packageRow.status,
            collaborator: packageRow.collaborator,
          } as NormalizedPickingPackageGroup;
        },
      );
      return {
        id: packageGroup.id,
        name: packageGroup.name,
        location: null,
        pickedUpAt: null,
        placeOnRampAt: null,
        collaborator: null,
        status: null,
        subRows,
      } as NormalizedPickingPackageGroup;
    });
  }, [lineItem]);

  if (isLoading) return null;

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(lineItem);

  return (
    <Modal
      close={close}
      isOpen
      width={750}
      title={
        <Typography fontSize="20" fontWeight="700">
          Szczegóły zamówienia {lineItem.signature}
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className={styles.lineItemTitle}>
          <Typography fontSize="26" fontWeight="700">
            {lineItem.picking.signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <img alt="picking" src={pickingIcon} style={{ height: 24, width: 24 }} />
            <Typography color="yellow300" fontSize="20" fontWeight="700">
              Picking
            </Typography>
          </div>
          {lineItem.picking.ramp && (
            <Tag label={lineItem.picking.ramp.name} variant="deepPurple50" />
          )}
        </div>
        <div className={styles.lineItemSection}>
          <div className={styles.lineItemHeader}>
            <div className={styles.lineItemHeaderGeneral}>
              <Typography fontSize="16" fontWeight="700">
                {lineItem.signature}
              </Typography>
              <Tag
                label={statusDict[lineItem.status].label}
                variant={statusDict[lineItem.status].variant}
              />
              {lineItem.address.countryCode && (
                <div className="d-flex align-items-center gap-1">
                  <img alt="kraj" src={countryCodesDict[lineItem.address.countryCode]} />
                  <Typography fontSize="10" fontWeight="700">
                    {lineItem.address.countryCode}
                  </Typography>
                </div>
              )}
            </div>
            <div className={styles.lineItemHeaderBuyer}>
              <Typography fontSize="12" fontWeight="700">
                {lineItem.buyer.firstName || lineItem.buyer.lastName
                  ? `${lineItem.buyer.firstName} ${lineItem.buyer.lastName}`
                  : "---"}
              </Typography>
            </div>
            <IconButton
              icon={<MdiQrCode size="16" />}
              onClick={event => {
                event.stopPropagation();
                handleDownloadPickingLineItemPdf(
                  lineItem.id,
                  lineItem.picking.id,
                  lineItem.signature,
                );
              }}
              variant="blackT"
            />
          </div>
          <div className={styles.tableContainer}>
            <Table<NormalizedPickingPackageGroup>
              rows={normalizedData}
              columns={columns}
              isLoading={isLoading || isFetching}
              error={error}
              uiSchema={comfortableListUiSchema}
              overrides={row => {
                return {
                  row: {
                    className: cx({
                      [styles.finishedItem]: row.status === PickingPackageStatus.ON_RAMP,
                      [styles.inProgressItem]:
                        row.status === PickingPackageStatus.ON_THE_WAY ||
                        row.status === PickingPackageStatus.PICKED_UP,
                    }),
                  },
                };
              }}
            />
          </div>
        </div>
        <div>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
