import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import { useQuery } from "hooks";
import { getTabs } from "./utils/getTabs";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useManufacturerItemsColumns } from "./useManufacturerItemsColumns";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanel } from "./rightPanel/RightPanel";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { ExternalManufacturerItem } from "api/external-manufacturing/models";

export const ManufacturerItemsList = ({ match }: RouteComponentProps<{ tab: any }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: manufacturerItems,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = externalManufacturingActions.useManufacturerItems(search);
  const columns = useManufacturerItemsColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        createButton={{
          alt: "utwórz",
          img: darkPlusIcon,
          label: "Utwórz zlecenie",
          onClick: () => {},
        }}
        hideModulesNavigation
        tabs={{
          list: getTabs(),
          routesRoot: `external-manufacturer-panel/manufacturer-items`,
          urlSpan: "list",
        }}
        viewLabel="EXTERNAL_MANUFACTURER_ITEMS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <DrawerHelpers name="externalManufacturerItems">
          {drawerHelpers => (
            <>
              <Table<ExternalManufacturerItem>
                rows={manufacturerItems}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
      </div>
    </PageWrapper>
  );
};
