import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { CourierBooking, Shipment } from "./models";
import { dateFns } from "utilities";
import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { createPaginatedKey } from "api/keys";

const api = tokenRefreshMiddleware(apiFetch);

export const postShipmentAndBookCourier = (orderId: string | number) =>
  api<CourierBooking>({
    method: "POST",
    url: "/shipping/create-shipment-and-book-courier",
    data: { order: orderId },
  });

export const shippingFileFactory = (() => {
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    shippingLabelPdf: (search: string) => ({
      url: `/shipping/get-label${search}`,
      name: `Zamówienia-${getDate()}`,
    }),
    shippingLabels: (shipmentId: UUID, shipmentServiceId: UUID) => ({
      url: `/shipping/labels/${shipmentServiceId}/${shipmentId}`,
      name: `Etykiety-${getDate()}`,
    }),
  };
})();

const getShippingShipments = (search: string = ""): ApiFetcher<Pagination<Shipment>> => ({
  key: createPaginatedKey("shippingShipments")(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/shipping/shipments" + search,
    }),
});

const postShippingShipment = (data: {
  shipmentService: number;
  orders: number[];
  minimumPickupDate: string;
  maximumPickupDate: string;
}) =>
  queryFetch<any>({
    method: "POST",
    url: "/shipping/create-shipments",
    data,
  });

export const shippingApi = {
  getShippingShipments,
  postShippingShipment,
};
