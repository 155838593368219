import { UUID } from "api/types";
import { UserWithInitials } from "api/users/models";

export type Priority = 0 | 1 | 2;

export enum ExternalManufacturerItemsTab {
  "not-sent" = "not-sent",
  "sent" = "sent",
  "received" = "received",
  "cancelled" = "cancelled",
  "all" = "",
}

interface Label {
  id: string;
  code: string;
  name: string;
  total: number;
  position: number;
  kindCode: string;
  parameters: {
    width: number;
    height: number;
    length: number;
    weight: number;
  };
  description: string;
  uniqueCode: string;
}

export interface OrderLineItemDetails {
  id: UUID;
  signature: string;
  isCancelled: boolean;
  name: string;
  manufacturerName: string;
  order: ExternalManufacturerLineItem["order"];
  priority: number;
  orderedAt: string | null;
  orderStatus: OrderStatus;
  isNew: boolean;
  executionStatus: ExecutionStatus;
  pickedUpBySeller: boolean;
  manufacturingOrder: ExternalManufacturerLineItem["manufacturingOrder"];
  deliveryGroup: ExternalManufacturerLineItem["deliveryGroup"];
  estimatedDeliveryDate: string | null;
  deliveredAt: string | null;
  orderedBy: UserWithInitials;
  hasConfirmedDeliveryDate: boolean;
  deliveryDateConfirmedAt: string | null;
  deliveryDateConfirmedBy: UserWithInitials | null;
  labels: Label[];
}
export interface ExternalManufacturingOrder {
  createdAt: string;
  deliveredToWarehouseAt: string | null;
  emailSentStatus: EmailSentStatus;
  estimatedTimeOfSendingEmail: string | null;
  expectedWarehouseDeliveryDate: string | null;
  fulfillmentAt: string | null;
  id: UUID;
  lineItems: {
    inProgress: number;
    notStarted: number;
    readyToDeliver: number;
    total: number;
  };
  manufacturer: { id: number; name: string };
  name: string;
  orderStatus: OrderStatus;
  orderedBy: UserWithInitials[];
  orders: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  }[];
  priority: Priority;
  progress: number;
  qrCode: string;
  sentAt: string | null;
  signature: string;
  updatedAt: string;
}

export enum PriorityChoice {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum EmailSentStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  SEEN = "SEEN",
  ERROR = "ERROR",
}

export enum OrderStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  RECEIVED = "RECEIVED",
}

export enum ExecutionStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_DELIVER = "READY_TO_DELIVER",
}

export interface ExternalManufacturerLineItem {
  deliveredAt: string | null;
  deliveryDateConfirmedAt: string | null;
  deliveryDateConfirmedBy: UserWithInitials;
  deliveryGroup: {
    description: string;
    id: number;
    kind: "ROUTE" | "ORDER_GROUP";
    signature: string;
  };
  estimatedDeliveryDate: string | null;
  executionStatus: ExecutionStatus;
  hasConfirmedDeliveryDate: boolean;
  id: UUID;
  isCancelled: boolean;
  isNew: boolean;
  labels: any[];
  manufacturerName: string;
  manufacturingOrder: {
    id: UUID;
    signature: string;
  };
  name: string;
  order: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  };
  orderStatus: OrderStatus;
  orderedAt: string | null;
  orderedBy: UserWithInitials;
  pickedUpBySeller: boolean;
  position: number;
  priority: Priority;
  signature: string;
}

export interface OrderLineItemListItem {
  id: UUID;
  position: number;
  signature: string;
  manufacturingOrder: string;
  name: string;
  manufacturerName: string;
  salesChannel: {
    name: string;
    textColor: string;
  };
  manufacturer: {
    id: number;
    name: string;
  };
  description: string;
  order: {
    id: number;
    signature: string;
    description: string;
    externalId: string;
  };
  orderedBy: UserWithInitials;

  orderedAt: string | null;
  priority: PriorityChoice;
  seller: {
    id: UUID;
    name: string;
    address: string;
  };
  supplier: {
    id: number;
    name: string;
  };
  isCancelled: number;
  executionStatus: ExecutionStatus;
  labels: Label[];
  orderStatus: OrderStatus;
  deliveryGroup: {
    id: number;
    kind: "ROUTE" | "ORDER_GROUP";
    signature: string;
    description: string;
  };
  estimatedDeliveryDate: string | "2024-03-04";
  fulfillmentAt: string | null;
  deliveredAt: string | null;
  createdAt: string;
}
export interface ExternalManufacturerItem {
  createdAt: string;
  deliveredToWarehouseAt: string | null;
  emailSentStatus: EmailSentStatus;
  estimatedTimeOfSendingEmail: string | null;
  expectedWarehouseDeliveryDate: string | null;
  fulfillmentAt: string | null;
  id: UUID;
  name: string;
  notificationsSent: any[];
  numOfLineItems: {
    inProgress: number;
    notStarted: number;
    readyToDeliver: number;
    total: number;
  };
  orderStatus: OrderStatus;
  orderedBy: UserWithInitials[];
  orders: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  }[];
  priority: Priority;
  progress: number;
  qrCode: string;
  sentAt: string | null;
  signature: string;
}

export interface SendNotificationToRecipient {
  manufacturingOrder: UUID;
  recipientEmail?: string;
}
