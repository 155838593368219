import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";
import { noop } from "utilities";

interface Props {
  close: () => void;
  signature: string;
}

export const PanelHeader = ({ close, signature }: Props) => {
  return (
    <RightPanelHeader>
      <Typography fontSize="16" fontWeight="600">
        {signature}
      </Typography>
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Podgląd etykiety">
          <IconButton icon={<MdiQrCode size="16" />} onClick={noop} variant="blackT" />
        </Tooltip>
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="blackT" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
