import { UnloadingLineItem, UnloadingStatus } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { InStockTime } from "./InStockTime";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import styles from "../../RightPanel.module.css";
import { cx } from "utilities";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { useDownloadUnloadingLineItemPdf } from "../../hooks/useDownloadUnloadingLineItemPdf";

export const useLineItemsColumns = () => {
  const handleDownloadUnloadingLineItemPdf = useDownloadUnloadingLineItemPdf();

  return useCreateTableColumns<UnloadingLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 110,
        cell: info => {
          const unloading = info.getValue();
          return (
            <Typography
              className={cx({
                [styles.cancelledSignature]: unloading.isCancelled,
              })}
              fontSize="12"
              fontWeight="700"
            >
              {unloading.signature}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "",
        id: "status",
        size: 50,
        cell: info => {
          const status = info.getValue();
          if (status === UnloadingStatus.FINISHED) {
            return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
          }
          if (status === UnloadingStatus.IN_PROGRESS) {
            return <Tag label="trwa" variant="warning" />;
          }
          return null;
        },
      }),
      columnHelper.accessor(row => row.suppliers, {
        header: "kontrahent",
        size: 95,
        cell: info => {
          const suppliers = info.getValue();
          if (suppliers && Boolean(suppliers.length)) {
            return (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {suppliers[0].name}
                </Typography>
                {suppliers.length - 1 > 0 && (
                  <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                    +{suppliers.length - 1}
                  </Typography>
                )}
              </div>
            );
          }
          return <EmptyValue />;
        },
      }),
      columnHelper.accessor(row => row.numberOfPackageGroups, {
        header: "towar",
        size: 40,
        cell: info => {
          const numberOfPackageGroups = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="14" fontWeight="700">
                {numberOfPackageGroups.inStock}
              </Typography>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                /{numberOfPackageGroups.total}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.inStockAt, {
        header: "rozładowano",
        size: 105,
        cell: info => {
          return <InStockTime date={info.getValue()} />;
        },
      }),
      columnHelper.text(
        row => {
          if (Boolean(row.locations)) return row.locations.join(", ");
          return null;
        },
        {
          header: "lokalizacja",
          size: 120,
        },
      ),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "labels",
        size: 75,
        cell: info => {
          const unloadingLineItem = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadUnloadingLineItemPdf(
                    unloadingLineItem.id,
                    unloadingLineItem.unloading,
                    unloadingLineItem.signature,
                  );
                }}
                variant="whiteT"
              />
              {unloadingLineItem.isCancelled ? (
                <Button
                  onClick={event => event.stopPropagation()}
                  size="small"
                  variant="transparent"
                >
                  Przywróć
                </Button>
              ) : (
                <Button
                  onClick={event => event.stopPropagation()}
                  size="small"
                  variant="transparent"
                >
                  Anuluj
                </Button>
              )}
            </div>
          );
        },
      }),
    ];
  });
};
