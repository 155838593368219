import { useQuery, useToastr } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "../manufacturerItemsList/utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { getTabs } from "../manufacturerItemsList/utils/getTabs";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useManufacturerLineItemsColumns } from "./useManufacturerLineItemsColumns";
import styles from "../shared/ExternalManufacturerPanel.module.css";
import { cx } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import {
  ExecutionStatus,
  ExternalManufacturerItemsTab,
  ExternalManufacturerLineItem,
} from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { priorityDict } from "../shared/components/PriorityLabel";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { MdiLocalShipping } from "components/miloDesignSystem/atoms/icons/MdiLocalShipping";
import { MdiFactory } from "components/miloDesignSystem/atoms/icons/MdiFactory";

export const ManufacturerLineItemsList = ({
  match,
}: RouteComponentProps<{ tab: ExternalManufacturerItemsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: manufacturerLineItems,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = externalManufacturingActions.useManufacturerLineItems(search);
  const columns = useManufacturerLineItemsColumns();
  const bulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateLineItems();
  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);
  const toastr = useToastr();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        hideModulesNavigation
        tabs={{
          list: getTabs(),
          routesRoot: `external-manufacturer-panel/manufacturer-line-items`,
          urlSpan: "list",
        }}
        viewLabel="EXTERNAL_MANUFACTURER_LINE_ITEMS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <DrawerHelpers name="externalManufacturerItems">
          {drawerHelpers => (
            <>
              <Table<ExternalManufacturerLineItem>
                rows={manufacturerLineItems}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                onRowClick={(rowId, e) => {
                  const index = manufacturerLineItems.findIndex(e => e.id === rowId);
                  handleMultipleRowsHighlight(
                    e,
                    highlightedRows,
                    rowId,
                    index,
                    setHighlightedRows,
                    () => drawerHelpers.togglePanel(rowId),
                    manufacturerLineItems,
                  );
                }}
                overrides={row => {
                  const className = getTablePropsBasedOnDrawerHelpers(drawerHelpers).overrides;

                  if (highlightedRows.find(r => r.id === row.id)) {
                    return {
                      row: {
                        className: styles.selected,
                      },
                    };
                  }
                  if (row.isCancelled && className) {
                    return {
                      row: {
                        className: cx(styles.cancelledRow, className(row).row?.className),
                      },
                    };
                  }
                  if (className)
                    return {
                      row: {
                        className: className(row).row?.className,
                      },
                    };
                  return {};
                }}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
        <TableToolbar
          close={() => {
            setHighlightedRows([]);
          }}
          numberOfSelectedItems={highlightedRows.length}
        >
          <Tooltip title="Potwierdź datę odbioru">
            <IconButton
              icon={MdiCheck}
              variant="whiteT"
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    hasConfirmedDeliveryDate: true,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Potwierdzono datę odbioru",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet A">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    priority: 2,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Ustawiono priorytet A",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[2].color}>
                  {priorityDict[2].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet B">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    priority: 1,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Ustawiono priorytet B",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[1].color}>
                  {priorityDict[1].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet C">
            <IconButton
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    priority: 0,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Ustawiono priorytet C",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
              icon={
                <Typography fontSize="12" fontWeight="600" color={priorityDict[0].color}>
                  {priorityDict[0].label}
                </Typography>
              }
              variant="whiteT"
            />
          </Tooltip>
          <Tooltip title="Oznacz status realizacji w produkcji">
            <IconButton
              icon={MdiFactory}
              variant="whiteT"
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    executionStatus: ExecutionStatus.IN_PROGRESS,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Ustawiono status w produkcji",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
            />
          </Tooltip>
          <Tooltip title="Oznacz status realizacji gotowe do odbioru">
            <IconButton
              icon={MdiCheck}
              variant="whiteT"
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    executionStatus: ExecutionStatus.READY_TO_DELIVER,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Ustawiono status w gotowe do odbioru",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
            />
          </Tooltip>
          <Tooltip title="Potwierdź odbiór przez zamawiającego">
            <IconButton
              icon={MdiLocalShipping}
              variant="whiteT"
              onClick={() => {
                bulkUpdateMutation.mutate(
                  {
                    ids: highlightedRows.map(e => String(e.id)),
                    pickedUpBySeller: true,
                  },
                  {
                    onSuccess: () => {
                      toastr.open({
                        text: "Potwierdzono odbiór przez zamawiającego",
                        title: "Udało się!",
                        type: "success",
                      });
                    },
                  },
                );
              }}
            />
          </Tooltip>
        </TableToolbar>
      </div>
    </PageWrapper>
  );
};
