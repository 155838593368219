import { useLoadingLineItems } from "api/wms/hooks";
import { LoadingDetails, LoadingLineItem, LoadingStatus } from "api/wms/models";
import { useFilters } from "hooks/useFilters";
import { useLineItemsColumns } from "./useLineItemsColumns";
import { RightPanelSection } from "components/utils/drawer";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { cx } from "utilities";
import styles from "pages/wms/shared/Wms.module.css";
import { useStateModal } from "hooks";
import { UUID } from "api/types";
import { LineItemsDetailsModal } from "../lineItemsDetailsModal/LineItemsDetailsModal";

interface Props {
  loading: LoadingDetails;
}

export const LineItemsSection = ({ loading }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, loading: loading.id });
  const { data: orders, error, isLoading, isPreviousData, pagination } = useLoadingLineItems(
    searchParams,
  );
  const columns = useLineItemsColumns();
  const lineItemDetailsModal = useStateModal<UUID>();

  return (
    <>
      <RightPanelSection title="Zamówienia">
        <div className="pb-3">
          <Table<LoadingLineItem>
            rows={orders}
            columns={columns}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            onRowClick={rowId => lineItemDetailsModal.open(String(rowId))}
            overrides={row => {
              return {
                row: {
                  className: cx({
                    [styles.cancelledItem]: row.isCancelled,
                    [styles.finishedItem]:
                      row.status === LoadingStatus.FINISHED && !row.isCancelled,
                    [styles.inProgressItem]:
                      row.status === LoadingStatus.IN_PROGRESS && !row.isCancelled,
                  }),
                },
              };
            }}
          />
        </div>
      </RightPanelSection>
      {lineItemDetailsModal.isOpen && lineItemDetailsModal.state && (
        <LineItemsDetailsModal
          close={lineItemDetailsModal.close}
          lineItemId={lineItemDetailsModal.state}
        />
      )}
    </>
  );
};
