import React from "react";
import { BaseIcon } from "../icons/types";
import { Typography } from "../typography";
import styles from "./Tag.module.css";
import cx from "classnames";
import { ColorPalette } from "../colorsPalette";

const DEFAULT_ICON_SIZE = "14";

type TagVariant =
  | "deepPurple400"
  | "deepPurple50"
  | "outline"
  | "quaternary"
  | "success"
  | "danger"
  | "info"
  | "warning";

type TagTheme = "light" | "dark";

type TagType = "filled" | "outlined";

const disabledThemeSelector: Record<TagTheme, { color: ColorPalette }> = {
  light: {
    color: "neutralBlack48",
  },
  dark: {
    color: "neutralWhite48",
  },
};

const variantSelector: Record<
  TagTheme,
  Record<
    TagVariant,
    Record<
      TagType,
      {
        color: ColorPalette;
      }
    >
  >
> = {
  light: {
    deepPurple400: {
      filled: { color: "neutralWhite100" },
      outlined: { color: "deepPurple400" },
    },
    deepPurple50: {
      filled: { color: "deepPurple500" },
      outlined: { color: "deepPurple500" },
    },
    outline: {
      filled: { color: "neutralBlack88" },
      outlined: { color: "neutralBlack88" },
    },
    quaternary: {
      filled: { color: "neutralBlack88" },
      outlined: { color: "neutralBlack88" },
    },
    success: {
      filled: { color: "success600" },
      outlined: { color: "success600" },
    },
    danger: {
      filled: { color: "danger600" },
      outlined: { color: "danger600" },
    },
    info: {
      filled: { color: "info600" },
      outlined: { color: "info600" },
    },
    warning: {
      filled: { color: "warning600" },
      outlined: { color: "warning600" },
    },
  },
  dark: {
    deepPurple400: {
      filled: { color: "neutralWhite100" },
      outlined: { color: "deepPurple400" },
    },
    deepPurple50: {
      filled: { color: "deepPurple400" },
      outlined: { color: "deepPurple400" },
    },
    outline: {
      filled: { color: "neutralWhite88" },
      outlined: { color: "neutralWhite88" },
    },
    quaternary: {
      filled: { color: "neutralWhite88" },
      outlined: { color: "neutralWhite88" },
    },
    success: {
      filled: { color: "success400" },
      outlined: { color: "success400" },
    },
    danger: {
      filled: { color: "danger400" },
      outlined: { color: "danger400" },
    },
    info: {
      filled: { color: "info400" },
      outlined: { color: "info400" },
    },
    warning: {
      filled: { color: "warning400" },
      outlined: { color: "warning400" },
    },
  },
};

export interface TagProps<T extends string> {
  disabled?: boolean;
  endIcon?: React.FunctionComponent<BaseIcon>;
  label: T;
  startIcon?: React.FunctionComponent<BaseIcon>;
  theme?: TagTheme;
  type?: TagType;
  variant: TagVariant;
}

export const Tag = <T extends string>({
  disabled = false,
  endIcon: EndIcon,
  label,
  startIcon: StartIcon,
  theme = "light",
  type = "filled",
  variant,
}: TagProps<T>) => {
  const getVariantStyling = (): { className: string; color: ColorPalette } => {
    if (disabled)
      return { className: `${theme}-disabled`, color: disabledThemeSelector[theme].color };
    return {
      className: `${theme}-${variant}-${type}`,
      color: variantSelector[theme][variant][type].color,
    };
  };

  return (
    <div className={cx(styles.tag, styles[getVariantStyling().className])}>
      {Boolean(StartIcon) && StartIcon !== undefined && (
        <StartIcon color={getVariantStyling().color} size={DEFAULT_ICON_SIZE} />
      )}
      <Typography color={getVariantStyling().color} fontSize="10" fontWeight="700">
        {label}
      </Typography>
      {Boolean(EndIcon) && EndIcon !== undefined && (
        <EndIcon color={getVariantStyling().color} size={DEFAULT_ICON_SIZE} />
      )}
    </div>
  );
};
