import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { PriorityLabel } from "../shared/components/PriorityLabel";
import { CreatedAtLabel } from "../shared/components/CreatedAtLabel";
import { ExecutionStatus } from "./components/ExecutionStatus";
import { LinkToPage } from "components/utils/linkToPage";
import { EstimatedDelivery } from "./components/EstimatedDelivery";
import { cx, getStandardDateFormat } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import styles from "../shared/ExternalManufacturerPanel.module.css";
import { ExternalManufacturerLineItem } from "api/external-manufacturing/models";

export const useManufacturerLineItemsColumns = () => {
  return useCreateTableColumns<ExternalManufacturerLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 170,
        cell: info => {
          const lineItem = info.getValue();
          return (
            <div
              className={cx(
                styles.signatureContainer,
                "d-flex align-items-center justify-content-between pr-1",
              )}
            >
              <Typography fontSize="14" fontWeight="700">
                {lineItem.signature}
              </Typography>
              {lineItem.isNew && !lineItem.isCancelled && <Tag label="nowe" variant="info" />}
              {lineItem.isCancelled && <Tag label="anulowane" variant="warning" />}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.name, {
        header: "produkt",
        size: 200,
      }),
      columnHelper.text(row => row.order.signature, {
        header: "zamówienie",
        size: 110,
        typographyProps: {
          color: "deepPurple400",
        },
      }),
      columnHelper.accessor(row => row.priority, {
        header: "priorytet",
        size: 56,
        cell: info => {
          const priority = info.getValue();
          return <PriorityLabel priority={priority} />;
        },
      }),
      columnHelper.text(row => row.manufacturerName, {
        header: "nazwa producenta",
        size: 110,
      }),
      columnHelper.accessor(row => row.orderedAt, {
        header: "zlecono",
        size: 100,
        cell: info => {
          const orderedAt = info.getValue();
          if (!orderedAt) return <EmptyValue />;
          return <CreatedAtLabel createdAt={orderedAt} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.accessor(row => row.executionStatus, {
        header: "status realizacji",
        size: 110,
        cell: info => {
          const status = info.getValue();
          return <ExecutionStatus status={status} />;
        },
      }),
      columnHelper.text(row => "---", {
        header: "status odbioru",
        size: 85,
      }),
      columnHelper.accessor(row => row.pickedUpBySeller, {
        header: "zamawiający odebrał",
        size: 120,
        cell: info => {
          const pickedUpBySeller = info.getValue();
          if (pickedUpBySeller) {
            return <Tag label="Tak" variant="success" />;
          }

          return <Tag label="Nie" variant="danger" />;
        },
      }),
      columnHelper.accessor(row => row.manufacturingOrder, {
        header: "ZDP",
        size: 110,
        cell: info => {
          const order = info.getValue();
          return (
            <div onClick={event => event.stopPropagation()}>
              <LinkToPage
                content={order.signature}
                url={`/external-manufacturer-panel/manufacturer-items/list/all?panelId=${order.id}`}
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.deliveryGroup.signature, {
        header: "trasa",
        size: 110,
        typographyProps: {
          color: "deepPurple400",
        },
      }),
      columnHelper.accessor(row => row.estimatedDeliveryDate, {
        header: "planowany odbiór",
        size: 180,
        cell: info => {
          const date = info.getValue();
          return <EstimatedDelivery estimatedDelivery={date} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.text(row => (row.deliveredAt ? getStandardDateFormat(row.deliveredAt) : null), {
        header: "odebrano",
        size: 100,
      }),
      columnHelper.accessor(row => row.orderedBy, {
        header: "zlecono przez",
        size: 130,
        cell: info => {
          const user = info.getValue();
          return <Avatar size="sm" theme="light" user={user} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: () => {
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  // TODO: download label
                }}
                variant="blackST"
              />
            </div>
          );
        },
      }),
    ];
  });
};
