import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  ExecutionStatus,
  ExternalManufacturerItem,
  ExternalManufacturerLineItem,
  ExternalManufacturingOrder,
  OrderLineItemDetails,
  PriorityChoice,
  SendNotificationToRecipient,
  OrderLineItemListItem,
} from "./models";
import { externalManufacturingKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { omit } from "utilities";

const getOrders = (search: string = ""): ApiFetcher<Pagination<ExternalManufacturingOrder>> => ({
  key: externalManufacturingKeys.main.manufacturingOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/items" + search,
    }),
});

const getManufacturingOrder = (id: UUID): ApiFetcher<ExternalManufacturingOrder> => ({
  key: externalManufacturingKeys.main.manufacturingOrders.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/items/" + id,
    }),
});

const getOrderLineItems = (search: string = ""): ApiFetcher<Pagination<OrderLineItemListItem>> => ({
  key: externalManufacturingKeys.main.orderLineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/line-items" + search,
    }),
});

const getOrderLineItem = (id: UUID): ApiFetcher<OrderLineItemDetails> => ({
  key: externalManufacturingKeys.main.orderLineItems.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/external-manufacturing/orders/line-items/${id}`,
    }),
});

const patchOrderLineItem = (data: PartialOf<OrderLineItemDetails>, id: UUID | number) =>
  queryFetch<OrderLineItemDetails>({
    method: "PATCH",
    url: `/external-manufacturing/orders/line-items/${id}`,
    data,
  });

const getManufacturerItems = (
  search: string = "",
): ApiFetcher<Pagination<ExternalManufacturerItem>> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-items" + search,
    }),
});

const getManufacturerItem = (id: UUID): ApiFetcher<ExternalManufacturerItem> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingOrders.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-items/" + id,
    }),
});

const getManufacturerLineItems = (
  search: string = "",
): ApiFetcher<Pagination<ExternalManufacturerLineItem>> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingLineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-line-items" + search,
    }),
});

const getManufacturerLineItem = (id: UUID): ApiFetcher<OrderLineItemDetails> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingLineItems.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/external-manufacturing/orders/manufacturer-line-items/${id}`,
    }),
});

const patchManufacturerLineItem = (data: PartialOf<OrderLineItemDetails>, id: UUID | number) =>
  queryFetch<OrderLineItemDetails>({
    method: "PATCH",
    url: `/external-manufacturing/orders/manufacturer-line-items/${id}`,
    data,
  });

const bulkUpdateLineItems = (args: {
  ids: UUID[];
  pickedUpBySeller?: boolean;
  executionStatus?: ExecutionStatus;
  priority?: PriorityChoice;
  estimatedDeliveryDate?: string;
  hasConfirmedDeliveryDate?: boolean;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "PATCH",
    url: `/external-manufacturing/orders/bulk-update-line-items?ids=${args.ids.join(",")}`,
    data: omit(args, "ids"),
  });

export const postNotificationToRecipient = (data: SendNotificationToRecipient) =>
  queryFetch<void>({
    method: "POST",
    url: "/external-manufacturing/orders/send-to-recipient",
    data,
  });

const postConfirmDeliveryDate = (id: UUID) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: `/external-manufacturing/orders/confirm-delivery-date/${id}`,
  });

export const externalManufacturingApi = {
  getOrders,
  getManufacturingOrder,
  getManufacturerItems,
  getManufacturerItem,
  bulkUpdateLineItems,
  getManufacturerLineItems,
  getManufacturerLineItem,
  patchManufacturerLineItem,
  postNotificationToRecipient,
  postConfirmDeliveryDate,
  patchOrderLineItem,
  getOrderLineItems,
  getOrderLineItem,
};
