import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import truckIcon from "assets/images/24.svg";
import { ShippingShipmentsRouting } from "routes/logistics/ShippingShipments";

export const shippingShipments: ModuleLink = {
  url: "shipping-shipments",
  label: "Przesyłki",
  icon: truckIcon,
  subSection: <></>,
  routing: ShippingShipmentsRouting,
};
