import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import manufacturerLineItemsIcon from "assets/images/singleOrders.svg";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";
import { executionStatusDict } from "pages/externalManufacturerPanel/manufacturerLineItemsList/components/ExecutionStatus";
import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";

export const manufacturerLineItems: ModuleLink = {
  url: "manufacturer-line-items",
  label: "Poj. zlecenia produkcyjne",
  icon: manufacturerLineItemsIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          label: "priorytet",
          name: "priority",
          options: Object.entries(priorityDict)
            .map(([key, value]) => ({
              label: value.label,
              value: key,
            }))
            .reverse(),
        },
        {
          type: FilterType.Date,
          label: "data odbioru",
          name: "estimatedDeliveryDate",
        },
        {
          type: FilterType.Select,
          label: "potwierdzono",
          name: "hasConfirmedDeliveryDate",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status realizacji",
          name: "executionStatus",
          options: Object.entries(executionStatusDict).map(([key, value]) => ({
            label: value.label.toLocaleLowerCase(),
            value: key,
          })),
        },
      ]}
    />
  ),
  routing: ManufacturerLineItems,
};
