import {
  ExternalManufacturerLineItem,
  OrderLineItemListItem,
} from "api/external-manufacturing/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { OrderStatusLabel } from "../../components/OrderStatusLabel";
import { FLAVOR } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LinkToPage } from "components/utils/linkToPage";
import { EstimatedDelivery } from "pages/externalManufacturerPanel/manufacturerLineItemsList/components/EstimatedDelivery";

export const useLineItemsColumns = () => {
  return useCreateTableColumns<OrderLineItemListItem | ExternalManufacturerLineItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => `${row.position}.`, {
          header: "#",
          size: 20,
        }),
        columnHelper.text(row => row.signature, {
          header: "sygnatura",
          size: 110,
        }),
        columnHelper.text(row => row.name, {
          header: "produkt",
          size: 210,
        }),
        columnHelper.accessor(row => row.orderStatus, {
          header: "status",
          size: 60,
          cell: info => {
            const status = info.getValue();
            return <OrderStatusLabel status={status} />;
          },
        }),
        columnHelper.accessor(row => row.estimatedDeliveryDate, {
          header: "planowy odbiór",
          size: 150,
          cell: info => {
            const date = info.getValue();
            return <EstimatedDelivery estimatedDelivery={date} fontSize="12" fontWeight="600" />;
          },
        }),
        columnHelper.accessor(row => row.order, {
          header: "zamówienie",
          size: 100,
          cell: info => {
            const order = info.getValue();
            if (FLAVOR === "externalManufacturing") {
              return (
                <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                  {order.signature}
                </Typography>
              );
            }
            return (
              <LinkToPage
                content={order.signature}
                url={`/orders/list/active/all?panelId=${order.id}`}
              />
            );
          },
        }),
      ];
    },
  );
};
