import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { OrderLineItemListItem } from "api/external-manufacturing/models";
import { UUID } from "api/types";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import { useLineItemsColumns } from "pages/externalManufacturerPanel/shared/rightPanel/lineItemsSection/useLineItemsColumns";

interface Props {
  id: UUID;
}

export const LineItemsSection = ({ id }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, manufacturingOrder: id });
  const {
    data: lineItems,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = externalManufacturingActions.useOrderLineItems(searchParams);
  const columns = useLineItemsColumns() as OrderLineItemListItem[];

  return (
    <RightPanelSection title="Lista pojedynczych">
      <div className="pb-3">
        <Table<OrderLineItemListItem>
          rows={lineItems}
          columns={columns}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={comfortableListUiSchema}
          pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        />
      </div>
    </RightPanelSection>
  );
};
