import { Driver } from "api/drivers/models";
import { DeadlineDayOptions } from "api/milo-settings/models";
import { Ramp } from "api/other/models";
import { UUID } from "api/types";
import { BaseUser, User } from "api/users/models";
import { WarehouseDocument } from "api/warehouse/models";
import { CommentType } from "components/common";
import { ColorPaletteBackground, ColorPaletteTextColor, CURRENCY_TYPE } from "CONSTANTS";
import { VehicleType } from "pages/routes/creator/leftPanel/pointsList/vehicleType/VehicleType";
import { Car } from "../cars/models";
import {
  Order,
  OrderForRoute,
  OrderMessagesSchema,
  OrderPaymentSource,
  OrderPaymentStatus,
  OrderPaymentType,
  OrderType,
  WarehouseDeliveryDetails,
} from "../orders/models";
import { PinToRouteAction } from "./enums";

export interface OrderPositionMeta {
  point: { lat: number; lng: number } | null;
  delivery: {
    distance: number | null;
    plannedDeliveryTime: string | null;
    stopoverTime: number;
    time: number | null;
    address: string | null;
  };
}

export interface OrderPositionTypes {
  order: {
    id: string;
    type: "order";
    meta: OrderPositionMeta;
    warehouseDeliveryDetails: {
      date: string | null;
      daysLeftToDelivery: number | null;
      isInWarehouse: boolean;
    } | null;
  };
  stopover: {
    id: string;
    type: "stopover";
    meta: OrderPositionMeta;
  };
  passpoint: {
    id: string;
    type: "passpoint";
    meta: OrderPositionMeta;
  };
}

export type OrderPositions = (
  | OrderPositionTypes["order"]
  | OrderPositionTypes["stopover"]
  | OrderPositionTypes["passpoint"]
)[];

export type RoutePriority = "HIGH" | "MEDIUM" | "LOW";

export const routePriorityDict: Record<RoutePriority, string> = {
  HIGH: "A",
  MEDIUM: "B",
  LOW: "C",
};

export type TrackingStatus = "AT_RISK" | "OFF_TRACK" | "ON_TRACK";

export const trackingStatusDict: Record<TrackingStatus, string> = {
  AT_RISK: "At risk",
  OFF_TRACK: "Off track",
  ON_TRACK: "On track",
};

export interface RouteListItem {
  car: Pick<
    Car,
    "id" | "totalLoad" | "cargo" | "registrationNumber" | "height" | "width" | "depth"
  > | null;
  color: string;
  created: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  loadingDate: string | null;
  loadingTime: string | null;
  departureDate: string | null;
  departureTime: string | null;
  driver: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
    userId: number;
  } | null;
  id: number;
  isDraft: boolean;
  isPristine: boolean;
  isRecentlyEdited: boolean;
  length: number;
  name: string;
  note: string;
  priority: RoutePriority;
  priorityOrder: number | null;
  ramp: Ramp | null;
  returnDate: null;
  signature: string;
  secondaryDriver: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
    userId: number;
  } | null;
  settledDetails: {
    id: number;
    settledBy: {
      id: number;
      avatar: string;
      firstName: string;
      lastName: string;
      phone: string;
    };
    created: string;
  } | null;
  startingPoint: {
    id: number;
    point: {
      lat: number;
      lng: number;
    };
    city: string;
    postCode: string;
    street: string;
    isDefault: boolean;
  } | null;
  status: Status;
  trackingStatus: TrackingStatus;
}

export interface Route {
  id: number;
  averageSpeed: string | null;
  signature: string;
  note: string;
  name: string;
  confirmedOrders: number;
  carrier: {
    id: number;
    name: string;
  } | null;
  carrierOrder: UUID | null;
  defaultStopoverTime: number;
  vehicleType: VehicleType["id"];
  ordersPositions: OrderPositions;
  paymentSummary: {
    confirmedKmPerOneCurrency: number;
    confirmedProfitPerOneKm: number;
    currency: string;
    kmPerOneCurrency: number;
    profitPerOneKm: number;
    summary: number;
    summaryConfirmedDelivery: number;
  }[];
  driver: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    userId: number;
  } | null;
  secondaryDriver: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    userId: number;
  } | null;
  settledDetails: {
    id: number;
    settledBy: {
      id: number;
      avatar: string;
      firstName: string;
      lastName: string;
      phone: string;
    };
    created: string;
  } | null;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  created: string;
  startingPoint: {
    id: number;
    point: {
      lat: number;
      lng: number;
    };
    city: string;
    postCode: string;
    street: string;
    isDefault: boolean;
  };
  status: Status;
  length: number;
  firstArrivalTime: string | null;
  firstArrivalDate: string | null;
  isDraft: boolean;
  hasPolyline: boolean;
  isPristine: boolean;
  loadingDate: string | null;
  loadingTime: string | null;
  departureDate: string | null;
  departureTime: string | null;
  priority: RoutePriority;
  priorityOrder: number | null;
  returnDistance: string;
  returnTime: number;
  returnDate: string | null;
  miniMap: null;
  car: Pick<
    Car,
    "id" | "totalLoad" | "cargo" | "registrationNumber" | "height" | "width" | "depth"
  > | null;
  ramp: Ramp | null;
  orders: RouteOrder[];
  comments: CommentType[];
  followers: { id: number; avatar: string; firstName: string; lastName: string }[];
  warehouseDocument: WarehouseDocument | null;
  includeLastPointInOptimization: boolean;
  receptions: Reception[];
}

export interface Reception {
  category: {
    id: number;
    name: string;
    picture: string | null;
  };
  document: {
    id: string;
    isFinished: boolean;
  } | null;
}

export interface OpenDocumentPayload {
  id: string;
  isFinished: boolean;
  orderGroup: number | null;
  productCategory: number;
  route: number | null;
}

export interface HistoryEntry {
  created: string;
  createdBy: Omit<User, "type" | "token" | "customer" | "profile">;
  id: string;
  index: {
    attributes: {
      attribute: string;
      value: string;
    }[];
    id: number;
    name: string;
  };
  quantity: number;
  reception: string;
}

export interface ReceptionEntryIndex {
  attributes: {
    attribute: string;
    value: string;
  }[];
  id: number;
  name: string;
  productCategory: Pick<Reception, "category">;
  received: number;
  requested: number;
}

export interface ReceptionEntriesPayload {
  id: string;
  indexes: ReceptionEntryIndex[];
  isFinished: boolean;
  orderGroup: {} | null;
  productCategory: number;
  route: {
    departureDate: string | null;
    departureTime: string | null;
    driver: Driver | null;
    id: number;
    length: number;
    loadingDate: string | null;
    loadingTime: string | null;
    name: string;
    secondaryDriver: Driver | null;
    signature: string;
  } | null;
}

export interface AddIndexPayload {
  created: string;
  createdBy: User;
  id: string;
  index: number;
  quantity: number;
  reception: string;
}

export interface RouteSettledDetails {
  amount: string;
  created: string;
  signature: string;
  currency: string;
  deliveries: {
    payment: {
      type: OrderPaymentType;
      id: number;
      paidAmount: string | number;
      discount: string | number;
      amountToCollect: string | number;
      source: OrderPaymentSource;
    };
    delivery: {
      city: string;
      amount: string;
      id: number;
      postCode: string;
      point: { lat: number; lng: number };
      street: string;
      phone: string;
      firstName: string;
      lastName: string;
    };
    isDelivered: boolean;
    isVisited: boolean;
    items: {
      amount: string;
      currency: string;
      id: number;
      index: number;
      quantity: number;
    }[];
    order: {
      amount: string;
      customer: {
        id: number;
        name: string;
        hasDropShipping: boolean;
        hasAmountToCollect: boolean;
      } | null;
      confirmedBy: { id: number; lastName: string; firstName: string };
      currency: string;
      customerId: number | null;
      id: number;
      missingAmount: string;
      signature: string;
      type: string;
    };
  }[];
  driver: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
    userId: number;
  };
  id: number;
  summary: {
    numPointsDelivered: number;
    numPointsNotDelivered: number;
    deliveredPointsTotalAmount: number;
    numPointsVisited: number;
    notDeliveredPointsTotalAmount: number;
    onlinePaymentTotalAmount: number;
    totalItemsAmount: number;
    cashToCollect: number;
    discount: number;
    deliveryAmount: number;
    paidByDebitCard: number;
    amountForTheDriver: number;
    prePaidAmount: number;
    distance: number;
  };
  route: number;
  settledBy: { id: number; firstName: string; lastName: string; avatar: string | null };
}

export type Status = "NEW" | "ROUTE_READY" | "DEPARTED" | "FINISHED" | "SETTLED";

export const routeStatusesToPick: { id: Status; name: string }[] = [
  { id: "DEPARTED", name: "W drodze" },
  { id: "FINISHED", name: "Ukończona" },
  { id: "NEW", name: "Nowa" },
  { id: "ROUTE_READY", name: "Gotowa" },
  { id: "SETTLED", name: "Rozliczona" },
];

export const routeStatusesOptions: { value: Status; label: string }[] = [
  { value: "DEPARTED", label: "W drodze" },
  { value: "FINISHED", label: "Ukończona" },
  { value: "NEW", label: "Nowa" },
  { value: "ROUTE_READY", label: "Gotowa" },
  { value: "SETTLED", label: "Rozliczona" },
];

export type AssignMultipleOrdersResponse = {
  orders: OrderForRoute[];
};

export interface RouteDriverScheduleItem {
  id: number;
  driver: { id: number; firstName: string; lastName: string };
  departureDate: string;
  departureTime: string | null;
  signature: string;
  priority: string;
}

interface RouteSettlementDriver {
  avatar: string | null;
  firstName: string;
  id: number;
  isDeleted: boolean;
  lastName: string;
  phone: string;
  userId: number;
}

export interface RouteSettlementSchema {
  driver: RouteSettlementDriver | null;
  id: number;
  orders: {
    client: { companyName: string; firstName: string; lastName: string; phone: string };
    customer: { id: number; hasDropShipping: boolean; hasAmountToCollect: boolean } | null;
    delivery: {
      amount: string | number;
      city: string;
      id: number;
      postCode: string;
      street: string;
      point: { lat: number; lng: number };
      distance: number | null;
      time: number | null;
      plannedDeliveryTime?: string | null;
      stopoverTime?: number | null;
    };
    id: number;
    hasSmsSent: boolean;
    isDeliveryDateConfirm: boolean;
    isSettled: boolean;
    isDelivered: boolean;
    isVisited: boolean;
    isCanceled: boolean;
    signature: string;
    leftDays: number;
    type: "STANDARD" | "COMPLAINT";
    payment: {
      type: OrderPaymentType;
      id: number;
      paidAmount: string | number;
      discount: string | number;
      amountToCollect: string | number;
      source: OrderPaymentSource;
    };
    totalVolume: number;
    totalWeight: number;
    items: {
      id: number;
      amount: number;
      quantity: number;
      product: {
        id: number;
        category: number | null;
      };
    }[];
  }[];
  returnDistance: string;
  secondaryDriver: RouteSettlementDriver | null;
  signature: string;
  status: Status;
}

export type SmsError = {
  error: "noDriver" | "noMessageToSend" | "resendSms" | "routeNotCalculated";
  message: string;
};

export type NoPhoneError = { error: "noPhone"; orders: string[] };
export type PostRouteSmsErrorResponse = (SmsError | NoPhoneError)[];

export interface RouteOrdersComments {
  id: number;
  orders: {
    id: number;
    messageToSeller: string;
    signature: string;
    isDeliveryDateConfirmed?: boolean;
    customer: { hasDropShipping: boolean; name: string; color: string; textColor: string } | null;
    delivery: {
      companyName: string;
      firstName: string;
      lastName: string;
      street: string;
      postCode: string;
      point: { lat: number; lng: number };
      city: string;
    };
    comments: CommentType[];
  }[];
}

export interface RoutePutSchema {
  length: number;
  ordersPositions: OrderPositions;
  operation: {
    method: "assign" | "unassign";
    orders: number[];
  } | null;
  returnDate: string | null;
  returnToStartingPointDistance: string;
  returnToStartingPointTime: string;
  deliveryTimes: {
    distance: number | null;
    order: number;
    plannedDeliveryTime: string | null;
    stopoverTime: number;
    time: number | null;
  }[];
  vehicleType?: Route["vehicleType"];
  startingPoint?: number;
  defaultStopoverTime?: number;

  averageSpeed?: string | null;
  departureTime?: string | null;
  departureDate?: string | null;
  firstArrivalTime?: string | null;
  includeLastPointInOptimization?: boolean;
}

export interface RoutePutMutationSchema {
  length: number;
  ordersPositions: OrderPositions;
  operation: {
    method: "assign" | "unassign";
    orders: number[];
  } | null;
  returnToStartingPointDistance: string;
  returnToStartingPointTime: string;
  shouldCalculateAverageSpeed: boolean;
  vehicleType?: Route["vehicleType"];
  startingPoint?: number;
  averageSpeed?: string | null;
  departureTime?: string | null;
  defaultStopoverTime?: number;
  departureDate?: string | null;
  firstArrivalTime?: string | null;
  firstArrivalDate?: string | null;
  includeLastPointInOptimization?: boolean;
}

export interface RouteOrder {
  isHidden: boolean;
  hideOnMapTo: string | null;
  hideUntilIssueIsSolved: boolean;
  id: number;
  signature: string;
  customer: {
    id: number;
    name: string;
    hasDropShipping: boolean;
    textColor?: string;
    color?: string;
  } | null;
  delivery: {
    id: number;
    postCode: string;
    city: string;
    street: string;
    price: { amount: number; currency: CURRENCY_TYPE };
    point: { lat: number; lng: number };
    distance: number | null;
    countryName: string;
    time: number | null;
    plannedDeliveryTime: string | null;
    shippingService: string | null;
    stopoverTime: number;
    amount: string | number;
    minimumCollectionHour: string | null;
    maximumCollectionHour: string | null;
    minimumCustomDeliveryHour: string | null;
    maximumCustomDeliveryHour: string | null;
  };
  client: {
    companyName: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  comments: number;
  items: {
    id: number;
    amount: number;
    quantity: number;
    product: {
      id: number;
      category: number | null;
    };
  }[];
  payment: {
    id: number;
    discount: number;
    paidAmount: number;
    status: OrderPaymentStatus;
    type: OrderPaymentType;
  };
  salesAccount?: {
    channel: string;
    id: number;
    name: string;
    color: ColorPaletteBackground;
    textColor: ColorPaletteTextColor;
    currency: CURRENCY_TYPE;
  };
  totalVolume: number | string;
  totalWeight: number | string;
  isSettled: boolean;
  isCanceled: boolean;
  isConfirmed: boolean;
  type: "STANDARD" | "COMPLAINT";
  leftDays: number;
  maximumCollectionHour?: string;
  maximumCollectionHourAtConfirmation?: string;
  minimumCollectionHour?: string;
  minimumCollectionHourAtConfirmation?: string;
  name?: string;
  numberOfDaysFromCreatedDate: number;
  hasUpholstery: boolean;
  method: "external-shipment" | "personal-collection" | "self-shipped";
  prices: { [k in string]: number };
  hasProducedItems: boolean;
  phone: string;
  isDeliveryDateConfirm: boolean;
  isDeliveryDateRejected: boolean;
  hasSmsSent: boolean;
  hasEmailSent: boolean;
  priority: string;
  warehouseDeliveryDetails: WarehouseDeliveryDetails;
}

export interface RouteMessages {
  route: {
    id: number;
    signature: string;
  };
  orders: {
    id: number;
    signature: string;
    client: {
      firstName: string;
      lastName: string;
      phone: string;
    };
    messages: {
      confirmationMessage: {
        body: string;
        failureReason: string;
        id: number | string;
        status: OrderMessagesSchema["messages"][number]["status"];
      } | null;
      lastIncomingMessage: {
        id: number;
        body: string;
      } | null;
    };
    type: OrderType;
    isCanceled: boolean;
    isSettled: boolean;
    maximumCollectionHourAtConfirmation: string;
    minimumCollectionHourAtConfirmation: string;
    name: string;
    isDeliveryDateConfirmed: boolean;
    isDeliveryDateRejected: boolean;
    deliveryConfirmationDeadline: string | null;
    deliveryDateConfirmation: string | null;
    deliveryDateConfirmationSource: "EXTERNAL" | "INTERNAL";
    deliveryDateConfirmedBy: {
      avatar: string | null;
      id: number;
      firstName: string;
      lastName: string;
    } | null;
  }[];
}

export interface RouteEmails {
  route: {
    id: number;
    signature: string;
  };
  orders: {
    id: number;
    signature: string;
    type: OrderType;
    isSettled: boolean;
    isCanceled: boolean;
    client: {
      firstName: string;
      lastName: string;
      email: string;
    };
    email: {
      id: number | string;
      subject: string;
      body: string;
      status: "SENT" | "OPENED" | "CLICKED_LINK" | "DECLINED";
      failureReason: string;
    } | null;
    isDeliveryDateConfirmed: boolean;
    deliveryConfirmationDeadline: string | null;
    deliveryDateConfirmTimestamp: string | null;
    deliveryDateConfirmationSource: "EXTERNAL" | "INTERNAL";
    deliveryDateConfirmedBy: {
      avatar: string | null;
      id: number;
      firstName: string;
      lastName: string;
    } | null;
  }[];
}

export interface OrderDeliveryPoint {
  client: {
    companyName: string;
    email: string;
    firstName: string;
    lastName: string;
    hasDropShipping: boolean;
    phone: string;
  };
  comments: {
    body: string;
    created: string;
    id: number;
    user: {
      avatar: string | null;
      firstName: string;
      id: number;
      isActive: boolean;
      isSuperuser: boolean;
      lastName: string;
      phone: string;
    };
  }[];
  customer: {} | null;
  deliveredDate: string;
  delivery: {
    amount: number;
    city: string;
    distance: number;
    id: number;
    maximumCollectionHour: string;
    minimumCollectionHour: string;
    phone: string;
    plannedDeliveryTime: string;
    point: {
      lng: number;
      lat: number;
    };
    postCode: string;
    price: {
      amount: number;
    };
    shippingService: null;
    stopoverTime: number;
    street: string;
    time: number;
  };
  hasProducedItems: boolean;
  id: number;
  isCanceled: boolean;
  isDelivered: boolean;
  isDeliveryDateConfirm: boolean;
  isVisited: boolean;
  leftDays: number;
  method: string;
  numberOfDaysFromCreatedDate: number;
  phone: string;
  prices: unknown;
  signature: string;
  type: string;
}

export interface OrderPosition {
  duration: number;
  id: string;
  meta: {
    delivery: {
      address: string | null;
      distance: number;
      plannedDeliveryTime: string;
      stopoverTime: number;
      time: number;
    };
    point: {
      lat: number;
      lng: number;
    };
  };
  type: "order" | "stopover" | "passpoint";
}

interface RouteDriver {
  avatar: string | null;
  firstName: string;
  id: number;
  isDeleted: boolean;
  lastName: string;
  phone: string;
  userId: number;
}

export interface RouteProgress {
  departureDate: string;
  departureTime: string;
  driver: RouteDriver | null;
  id: number;
  lastAndCurrentDeliveryPoint: {
    lastDelivery: OrderDeliveryPoint | null;
    currentDelivery: OrderDeliveryPoint | null;
  };
  length: number;
  name: string;
  orders: OrderDeliveryPoint[];
  ordersPositions: OrderPosition[];
  secondaryDriver: RouteDriver | null;
  signature: string;
  status: string;
}

export interface PostDeadline {
  option: DeadlineDayOptions;
  time: string;
}

export interface AcceptReceptionByProduct {
  product: number;
  attributes?: {
    id: number;
    values: number[];
  }[];
  quantity: number;
}

export interface PinToRoute {
  id: UUID;
  createdAt: string;
  createdBy: BaseUser;
  action: PinToRouteAction;
  actionAt: string;
  order: Pick<Order, "id" | "signature">;
  route: Pick<Route, "id" | "signature">;
}

export interface RampLoadingStatus {
  collected: number;
  index: {
    attributes: {
      attribute: string;
      id: number;
      value: string;
    }[];
    id: number;
    product: {
      category: string;
      id: number;
      name: string;
    };
  };
  requested: number;
}

export type AutomaticSmsSendStatus = "SENT" | "NOT_SENT" | "SENDING_ERROR";

export interface AutomaticSmsSchedule {
  id: UUID;
  route: {
    id: number;
    signature: string;
  };
  sendStatus: AutomaticSmsSendStatus;
  sentErrors: number;
  sentSuccess: number;
  smsSendDate: string | null;
  smsSendTime: string | null;
}

export interface HideOrderOnMap {
  hideOnMapTo: string | null;
  hideUntilIssueIsSolved: boolean;
  hideIssue: string;
}
