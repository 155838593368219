import { Row as RowType } from "@tanstack/react-table";
import { useQuery } from "hooks";
import { PropsWithChildren } from "react";
import { cx } from "utilities";
import { Assign } from "utility-types";
import { TABLE_PADDING_HORIZONTAL } from "../../../../constants";
import { BodyProps } from "../../Body";
import styles from "./Row.module.css";

type Props<T> = PropsWithChildren<Assign<Omit<BodyProps<T>, "uiSchema">, { row: RowType<T> }>>;

export const Row = <T,>({ children, table, onRowClick, overrides, row }: Props<T>) => {
  const { query } = useQuery();
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;

  const isRowHoverable = Boolean(onRowClick);
  const panelId = query[defaultPanelKey];
  const isRowActive = panelId === row.id;
  const isSubRow = row.depth;
  const hasSubRows = row.subRows.length;

  if (isSubRow)
    return (
      <SubRow row={row} overrides={overrides} table={table}>
        {children}
      </SubRow>
    );

  return (
    <div
      className={cx(
        overrides?.(row.original).row?.className,
        styles.row,
        "d-flex align-items-center",
        {
          [styles.hoverable]: isRowHoverable,
          [styles.active]: isRowActive,
          "border-bottom-0": hasSubRows,
        },
      )}
      style={{ width, maxWidth: width, minWidth: width }}
      onClick={e => onRowClick?.(row.id, e)}
    >
      {children}
    </div>
  );
};

const SubRow = <T,>({ children, overrides, table, row }: Props<T>) => {
  const isLastSubRow =
    row.getParentRow()?.subRows[(row.getParentRow()?.subRows.length || 0) - 1]?.id === row.id;
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;
  return (
    <div
      className={cx(
        overrides?.(row.original).row?.className,
        styles.row,
        "d-flex align-items-center",
        {
          [styles.nestedRow]: !isLastSubRow,
        },
      )}
      style={{ width, maxWidth: width, minWidth: width }}
    >
      {children}
    </div>
  );
};
const defaultPanelKey = "panelId";
