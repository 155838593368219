import { OrderStatus } from "api/external-manufacturing/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";

const orderStatusMap: Record<
  OrderStatus,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  NOT_SENT: { label: "niezlecono", variant: "warning" },
  SENT: { label: "zlecono", variant: "info" },
  RECEIVED: { label: "przyjęto na magazyn", variant: "success" },
};

interface Props {
  status: OrderStatus;
}

export const OrderStatusLabel = ({ status }: Props) => {
  return <Tag label={orderStatusMap[status].label} variant={orderStatusMap[status].variant} />;
};
