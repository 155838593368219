import { ExternalManufacturerItem } from "api/external-manufacturing/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { EstimatedTimeOfSendingEmail } from "pages/externalManufacturerPanel/shared/components/EstimatedTimeOfSendingEmail";

interface Props {
  manufacturerItem: ExternalManufacturerItem;
}

export const GeneralInfoSection = ({ manufacturerItem }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <InfoLabel title="planowana wysyłka">
        <EstimatedTimeOfSendingEmail
          estimatedTimeOfSendingEmail={manufacturerItem.estimatedTimeOfSendingEmail}
          fontSize="16"
          fontWeight="700"
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
