import { deepPurple } from "./colors/deepPurple";
import { neutralBlack } from "./colors/neutralBlack";
import { neutralWhite } from "./colors/neutralWhite";
import { info } from "./colors/info";
import { success } from "./colors/success";
import { warning } from "./colors/warning";
import { danger } from "./colors/danger";
import { pink } from "./colors/pink";
import { red } from "./colors/red";
import { deepOrange } from "./colors/deepOrange";
import { orange } from "./colors/orange";
import { yellow } from "./colors/yellow";
import { lime } from "./colors/lime";
import { lightGreen } from "./colors/lightGreen";
import { green } from "./colors/green";
import { teal } from "./colors/teal";
import { turquoise } from "./colors/turquoise";
import { blue } from "./colors/blue";
import { indigo } from "./colors/indigo";
import { purple } from "./colors/purple";
import { magenta } from "./colors/magenta";
import { grey } from "./colors/grey";

export type ColorPalette = keyof typeof colorPalette;

export const colorPalette = {
  ...deepPurple,
  ...neutralBlack,
  ...neutralWhite,
  ...info,
  ...grey,
  ...success,
  ...warning,
  ...danger,
  ...pink,
  ...red,
  ...deepOrange,
  ...orange,
  ...yellow,
  ...lime,
  ...lightGreen,
  ...green,
  ...teal,
  ...turquoise,
  ...blue,
  ...indigo,
  ...purple,
  ...magenta,
};
