import styles from "./HeaderUserDropdown.module.css";
import cx from "classnames";
import { useSelector, useRedux } from "hooks";
import settingsIcon from "assets/images/13.svg";
import logoutIcon from "assets/images/37.svg";
import { Button } from "components/common";
import { FLAVOR } from "CONSTANTS";
import { Link } from "react-router-dom";
import { PopoverMenu } from "components/utils/Popover";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { UserWithInitials } from "api/users/models";

interface Props {
  moduleLayoutStyle?: string;
}

export const HeaderUserDropdown = ({ moduleLayoutStyle }: Props) => {
  const me = useSelector(state => state.auth.user);
  const [dispatch, { auth, partials }] = useRedux();
  if (!me) {
    return null;
  }
  return (
    <PopoverMenu
      target={btnProps => (
        <div className={cx(styles.avatar)} role="button" {...btnProps}>
          <Avatar size="sm" theme="light" user={(me as unknown) as UserWithInitials} />
        </div>
      )}
    >
      {() => (
        <div className={styles.dropdown}>
          <div className="d-flex flex-column align-items-center">
            <Avatar size="xl" theme="light" user={(me as unknown) as UserWithInitials} />
            <div className="user-name mt-2">
              {me && (
                <>
                  {me.firstName} {me.lastName}
                </>
              )}
            </div>
          </div>
          <hr />
          <div className="d-flex flex-column">
            {FLAVOR === "main" && (
              <Button
                kind="secondary-grey"
                as={Link}
                to="/settings"
                className={cx(styles.option, "d-flex justify-content-start")}
              >
                <img src={settingsIcon} alt="Dane i ustawienia" />
                <span>Dane i ustawienia</span>
              </Button>
            )}
            <button
              className={cx(styles.option, "d-flex align-items-center")}
              type="button"
              onClick={() => {
                dispatch(partials.setPristine({ isPristine: true }));
                dispatch(auth.resetStore());
              }}
            >
              <img src={logoutIcon} alt="Wyloguj" />
              <span>Wyloguj</span>
            </button>
          </div>
        </div>
      )}
    </PopoverMenu>
  );
};
